import React from "react";
import { ScrollParallax } from "react-just-parallax";
import { Fade, Zoom, Slide, Roll } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

function TvBanner(props) {
  const bottomUpAnimation = keyframes`
    from { transform: translateY(50%); }
    to { transform: translateY(0) }
  `;
  const upBottomAnimation = keyframes`
    from { transform: translateY(-50%); }
    to { transform: translateY(0) }
  `;

  return (
    <div className="main-banner-box px-3">
      <div className="container">
        <ScrollParallax strength="-0.3" scrollContainerRef="-0.3">
          <div className="text-center">
            <h1>
              <span className="d-flex justify-content-center">
                <Slide
                  cascade
                  direction="up"
                  delay="200"
                  triggerOnce="ture"
                  keyframes={bottomUpAnimation}
                >
                  <span className="blink-anim" data-anim="Turn">
                    Turn
                  </span>
                </Slide>
                &nbsp;
                <Slide cascade direction="down" delay="200" triggerOnce="ture">
                  <span className="blink-anim" data-anim="ON">
                    ON
                  </span>
                </Slide>
              </span>
              <Zoom cascade delay="200" triggerOnce="ture">
                <div className="heading-video">
                  <div className="heading-video-box">
                    <video
                      preload="auto"
                      playsInline={true}
                      autoPlay={true}
                      muted={true}
                      loop={true}
                      poster={`${process.env.REACT_APP_TLD_IMAGE_URL}images/yellow-tv.jpg`}
                      src={`${process.env.REACT_APP_TLD_IMAGE_URL}videos/dot-tv main.mp4`}
                    ></video>
                  </div>
                </div>
              </Zoom>
              <span className="d-flex justify-content-center">
                <Slide
                  cascade
                  direction="down"
                  delay="200"
                  triggerOnce="ture"
                  keyframes={upBottomAnimation}
                >
                  <span className="blink-anim" data-anim="Your">
                    Your
                  </span>
                </Slide>
                &nbsp;
                <Slide
                  cascade
                  direction="down"
                  delay="200"
                  triggerOnce="ture"
                  keyframes={upBottomAnimation}
                >
                  <span className="blink-anim" data-anim="Domain">
                    Domain
                  </span>
                </Slide>
              </span>
            </h1>
            <Zoom delay="200" triggerOnce="ture">
              <span className="down-arrow-anim">
                <svg
                  width="18"
                  height="22"
                  viewBox="0 0 18 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.97402 1L9.97402 0.9L9.87402 0.9L8.08628 0.9L7.98484 0.9L7.98629 1.00144L8.23746 18.499L8.23802 18.5387L8.26563 18.5672L9.06418 19.3914L9.13888 19.4685L9.2105 19.3885L9.94852 18.5643L9.97402 18.5358L9.97402 18.4976L9.97402 1Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.2"
                  />
                  <path
                    d="M17.341 12.8429L17.4129 12.7691L17.3433 12.6927L16.0791 11.3068L16.0118 11.2329L15.9444 11.3068L8.33204 19.6524L8.28008 19.7094L8.31711 19.779L9.05487 21.165L9.11507 21.2781L9.20229 21.1886L17.341 12.8429Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.2"
                  />
                  <path
                    d="M2.33155 11.305L2.26416 11.2311L2.19677 11.305L0.932646 12.6909L0.862968 12.7673L0.934933 12.8411L9.07345 21.187L9.16067 21.2764L9.22087 21.1633L9.95879 19.7771L9.99583 19.7076L9.94387 19.6506L2.33155 11.305Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.2"
                  />
                </svg>
              </span>
            </Zoom>
            <div className="banner-text-details">
              <p>
                Whether launching a new film or music video, or streaming your
                day-to-day content, .TV is the domain you want for everything
                watchable. Get your .TV domain and share your content with the
                world.{" "}
              </p>
            </div>
          </div>
        </ScrollParallax>
      </div>
    </div>
  );
}

export default TvBanner;
