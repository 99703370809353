import React from "react";
import TvLogo from "../Logos/TvLogo";
import VolumeupdownAnimation from "../VolumeupdownAnimation";

function FaqBanner() {
  return (
    <>
      <div className="page-title-box">
        <div className="container">
          <div className="page-title">
            {/* <figure>
              <TvLogo />
            </figure> */}
            <h1 className="mt-md-2">FAQ</h1>
            <div className="mt-5 mb-4 mb-md-5">
              <VolumeupdownAnimation />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FaqBanner;
