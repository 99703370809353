import React, { useEffect, useState,useContext} from "react";
import { getAllDrawerChannelPartners } from "../../Services/tvAPI";
import { UtmContext } from "../../Context/ContextProvider";

function AvailableDrawerRegistrar({ tld_id, domain }) {
  const { utmSource, utmCampaign, utmMedium, utmTerm } = useContext(UtmContext);
  const [allDrawerChannelPartners, setAllDrawerChannelPartners] = useState([]);

  const arr = domain.split(".");
  const domain_without_tld = arr[0];

  useEffect(() => {
    if (tld_id !== null || tld_id !== "") {
      const allDrawerChannelPartners = async (tld_id) => {
        let data = await getAllDrawerChannelPartners(tld_id);
        setAllDrawerChannelPartners(data);
      };
      allDrawerChannelPartners(tld_id);
    }
  }, []);

  let timeout;
  function timeout_init(param) {
    window.dataLayer.push({
      event: "Featured Registrar Select Click",
      page: {
        url: window.location.href,
        title: "TurnOn.tv",
      },
    });

    timeout = setTimeout(() => {
      timeout_trigger(param);
    }, 3000);
  }

  function timeout_trigger(param) {
    // let newTab = window.open("", "_blank");
    // newTab.location.href = param; 
    window.location = param; 
  }

  function timeout_clear() {
    clearTimeout(timeout);
  }

  return (
    <>
      {Object.keys(allDrawerChannelPartners).length > 0 ? (
        <>
          <div className="p-0 card-body text-md-center">
            <div className="trusted-all-partner-list">
              <div className="row g-3 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
                {Object.keys(allDrawerChannelPartners).map(function (
                  drawerkey,
                  index
                ) {
                  let drawer_url =
                    allDrawerChannelPartners[drawerkey].drawer_url;
                  let drawer_url_replace = drawer_url
                    .replace("example.tv", domain_without_tld ? domain_without_tld + ".tv" : "example.tv")
                    .replace("example", domain_without_tld ? domain_without_tld : "example");

                  if (utmSource !== "") {
                    drawer_url_replace = drawer_url_replace + "&utm_source=" + utmSource;
                  }
  
                  if (utmMedium !== "") {
                    drawer_url_replace = drawer_url_replace + "&utm_medium=" + utmMedium;
                  }
  
                  if (utmCampaign !== "") {
                    drawer_url_replace = drawer_url_replace + "&utm_campaign=" + utmCampaign;
                  }
  
                  if (utmTerm !== "") {
                    drawer_url_replace = drawer_url_replace + "&utm_term=" + utmTerm;
                  }

                  return (
                    <div className="col" key={index}>
                      <div className="card text-md-center">
                        <figure>
                          <img
                            src={
                              process.env.REACT_APP_API_URL +
                              "" +
                              allDrawerChannelPartners[drawerkey].logo_url
                            }
                            alt={allDrawerChannelPartners[drawerkey].icann_short_name}
                          />
                        </figure>
                        <p>{allDrawerChannelPartners[drawerkey].icann_short_name}</p>
                        <a
                          href={drawer_url_replace}
                          className="btn btn-sm btn-primary border-0"
                          data-bs-toggle="modal"
                          data-bs-target={"#drawerModal" + index}
                          onClick={() => timeout_init(drawer_url_replace)}
                        >
                          <span>Select</span>
                        </a>
                      </div>
                      <div
                        className="domain-model modal fade"
                        id={"drawerModal" + index}
                        tabIndex="-1"
                        aria-labelledby="drawerModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-lg modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-body text-center">
                              <h3>
                                You’ve selected&nbsp;
                                {allDrawerChannelPartners[drawerkey].icann_short_name}
                              </h3>
                              <p className="font-xl">
                                Sit tight, we’ll redirect you to complete your
                                purchase.
                              </p>
                              <p>
                                Or you may&nbsp;
                                <a
                                  href="/#"
                                  data-bs-dismiss="modal"
                                  onClick={() => timeout_clear()}
                                >
                                  cancel your selection
                                </a>
                                &nbsp;and return to the search results.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default AvailableDrawerRegistrar;
