import React from "react";
import TvLogo from "../Logos/TvLogo";
import VolumeupdownAnimation from "../VolumeupdownAnimation";
import { Reveal, Fade, Roll, Zoom } from "react-awesome-reveal";

function AccreditedRegistrarsBanner() {
  return (
    <>
    <Zoom>
      <div className="page-title-box">
        <div className="container">
          <div className="page-title accredited-registrars-title">
            <figure>
              <TvLogo />
            </figure>
            <h1>Accredited Registrars</h1>
            <p>Here’s a full list of our .TV Accredited Registrars.</p>
            <div className="mt-5 mb-0 mb-md-3">
              <VolumeupdownAnimation />
            </div>
          </div>
        </div>
      </div>
    </Zoom>
    </>
  );
}

export default AccreditedRegistrarsBanner;
