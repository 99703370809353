import React, { useEffect, useState, useRef } from "react";
import HeaderSearch from "../HeaderSearch";
import TvFooter from "../TvFooter/TvFooter";
import TvHeader from "../TvHeader/TvHeader";
import AccrditedRegistrarReseller from "./AccrditedRegistrarReseller";
import AccrditedRegistrarResellerForm from "./AccrditedRegistrarResellerForm";
import AccreditedRegistrarsBanner from "./AccreditedRegistrarsBanner";
import AccreditedRegistrarsContents from "./AccreditedRegistrarsContents";
import FooterScroll from "../FooterScroll";
import FooterSearchbar from "../FooterSearchbar";
import { useIsInViewport } from "../../Services/tvAPI";
import { Helmet } from "react-helmet";

function AccreditedRegistrars() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const ref2 = useRef(null);
  const isInViewport2 = useIsInViewport(ref2);

  const [footerSearchon, setFooterSearchon] = useState("headersearchshow");

  useEffect(() => {
    if (isInViewport2 === true) {
      setFooterSearchon("headersearchhide");
    } else {
      setFooterSearchon("headersearchshow");
    }
  }, [isInViewport2]);

  return (
    <>
      <Helmet>
        <title>.TV Accredited Registrar List &#38; Partner Request Form &#124; .TV Domain</title> 
        <meta name="website" content="http://turnon.tv/accreditedregistrars"/>
        <meta name="description" content="See a full list of .TV accredited registrars and access the registrar and reseller accreditation request form to become a partner today."/>
      </Helmet>
      <TvHeader />
      <div className={footerSearchon + ` home-page-search`}>
        <HeaderSearch url={window.location.pathname} />
      </div>
      <AccreditedRegistrarsBanner />
      <AccrditedRegistrarReseller />
      <AccreditedRegistrarsContents />
      <AccrditedRegistrarResellerForm />
      <FooterScroll />
      <div ref={ref2}>
        <FooterSearchbar url={window.location.pathname} />
      </div>
      <TvFooter />
    </>
  );
}

export default AccreditedRegistrars;
