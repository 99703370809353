import React from "react";

function TwitterLogo(props) {
  return (
    <svg
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 2.13095C20.2274 2.49272 19.397 2.73636 18.5255 2.84619C19.4154 2.28415 20.0987 1.39356 20.4199 0.33224C19.5877 0.852748 18.6655 1.23113 17.6838 1.43509C16.8989 0.551887 15.778 0 14.539 0C11.7574 0 9.71338 2.73728 10.3416 5.57886C6.762 5.38966 3.5875 3.5808 1.46213 0.831522C0.333375 2.87387 0.87675 5.54563 2.79475 6.89858C2.0895 6.87459 1.4245 6.67063 0.844375 6.33009C0.797125 8.43519 2.22775 10.4046 4.29975 10.843C3.69338 11.0165 3.02925 11.0571 2.35375 10.9205C2.9015 12.7257 4.49225 14.039 6.37875 14.0759C4.5675 15.5737 2.2855 16.2428 0 15.9586C1.90662 17.2478 4.172 18 6.6045 18C14.6038 18 19.1231 10.8744 18.8501 4.48339C19.6919 3.84198 20.4225 3.04184 21 2.13095Z"
        fill="white"
      />
    </svg>
  );
}

export default TwitterLogo;
