import React from "react";

function VolumeupdownAnimation(props) {
  return (
    <div className="devider">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}

export default VolumeupdownAnimation;
