import React from "react";
import { Reveal, Fade, Roll, Zoom } from "react-awesome-reveal";

function AboutContent() {
  return (
    <>
      <div className="section-row overfolw-hidden">
        <Fade damping={0.1}>
          <div className="clip-img-box px-2 px-md-0">
            <div className="clip-img">
              <h2
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_TLD_IMAGE_URL}images/About_us_banner_img.jpg)`,
                }}
              >
                Tuvalu
              </h2>
            </div>
          </div>
        </Fade>
        <Zoom>
          <div className="px-4 px-md-0 page-title">
            <p>
              Beyond representing streaming content, the .TV domain is the
              country code top-level domain for Tuvalu, an independent island
              nation in the South Pacific Ocean. The world’s fourth smallest
              country, the nine low-lying islands that make up Tuvalu are
              critically threatened by sea level rise due to climate change.
              When you register a .TV domain name, you are helping to support
              the Future Now Project – Tuvalu’s ongoing mission to mitigate the
              impact of climate change and to preserve its sovereignty as a
              Digital Nation.
            </p>
          </div>
        </Zoom>
      </div>
      <Zoom>
        <div className="section-row mb-md-5 px-4 px-md-0">
          <div className="container">
            <div className="text-center gd-text">
              <figure className="gd-ico mt-3 mt-md-4">
                <img
                  src={`${process.env.REACT_APP_TLD_IMAGE_URL}images/Shape.png`}
                  alt="Shape-img"
                />
              </figure>
              <h2 className="h1 text-lime mb-3">GoDaddy Registry</h2>
            </div>
            <div className="page-title">
              <p>
                GoDaddy Registry is a world-leading domain name registry provider.
                We provide the technical, operational, and security
                infrastructure that powers the resolution and resilience of one
                of the world’s largest and most diverse portfolios of top-level
                domain extensions, including .TV. To learn more about GoDaddy
                Registry, please visit{" "}
                <a
                  href="http://www.registry.godaddy/"
                  aria-label="registry.godaddy"
                  target="blank"
                >
                  registry.godaddy
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </Zoom>
    </>
  );
}

export default AboutContent;
