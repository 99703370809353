const availableInterviews = [
  "boardroom.tv",
  "brid.tv",
  "cookingwithgranny.tv",
  "maff.tv",
  "ninjalifehacks.tv",
  "piehole.tv",
  "pluto.tv",
  "tmilly.tv",
  "tdn.tv",
  "paulcostabile.tv",
  "alexrobinson.tv",
  "roaddirt.tv",
  "thehhn.tv",
  "brianklein.tv",
  "thecontentfarm.tv"
];

export default availableInterviews;
