const nextInterviewScroll = [
  {
    name: "Maff.tv",
    image: "images/interviewthumbnails/maffMarquee-img.jpg",
    link: "/whosontv/maff.tv",
  },
  {
    image: "images/interviewthumbnails/BoardroomLogo.png",
    link: "/whosontv/boardroom.tv",
    name: "Boardroom.tv",
  },
];

export default nextInterviewScroll;
