import React, { useRef, useState } from "react";
function StoriesFeaturedvideo(props) {
  const [playingdekstop, setPlayingdekstop] = useState(true);
  const [playingmobile, setPlayingmobile] = useState(true);
  const [playingtablet, setPlayingtablet] = useState(true);
  const videoRefdekstop = useRef(null);
  const videoRefmobile = useRef(null);
  const videoReftablet = useRef(null);

  const handleVideoPress = (e, reference, statesetter, mainState) => {
    e.preventDefault();
    if (mainState) {
      reference.current.pause();
      statesetter(false);
    } else {
      reference.current.play();
      statesetter(true);
    }
  };

  return (
    <a href="#" aria-label="Story Video">
      <div className="p-3 px-md-4">
        <div className="story-video-box">
          <video
            onClick={(e) => {
              handleVideoPress(
                e,
                videoRefdekstop,
                setPlayingdekstop,
                playingdekstop
              );
            }}
            className="video__player d-none d-md-none d-lg-block"
            preload="auto"
            playsInline={true}
            autoPlay={true}
            muted={true}
            loop={true}
            ref={videoRefdekstop}
            poster={`${process.env.REACT_APP_TLD_IMAGE_URL}images/yellow-tv.jpg`}
            src={`${process.env.REACT_APP_TLD_IMAGE_URL}videos/StoriesVideo/WF-634944_Registry_dotTV_CONTAINER_169_15.mp4`}
          ></video>
          {playingdekstop === false ? (
            <a
              href="#"
              onClick={(e) => {
                handleVideoPress(
                  e,
                  videoRefdekstop,
                  setPlayingdekstop,
                  playingdekstop
                );
              }}
              className="video-btn d-none d-md-none d-lg-block "
            >
              Play
            </a>
          ) : (
            ""
          )}
          <video
            onClick={(e) => {
              handleVideoPress(
                e,
                videoRefmobile,
                setPlayingmobile,
                playingmobile
              );
            }}
            className="video__player d-none d-md-block d-lg-none"
            preload="auto"
            playsInline={true}
            autoPlay={true}
            muted={true}
            loop={true}
            ref={videoRefmobile}
            poster={`${process.env.REACT_APP_TLD_IMAGE_URL}images/yellow-tv.jpg`}
            src={`${process.env.REACT_APP_TLD_IMAGE_URL}videos/StoriesVideo/WF-634944_Registry_dotTV_CONTAINER_916_15.mp4`}
          ></video>
          {playingmobile === false ? (
            <a
              href="#"
              onClick={(e) => {
                handleVideoPress(
                  e,
                  videoRefmobile,
                  setPlayingmobile,
                  playingmobile
                );
              }}
              className="video-btn d-none d-md-block d-lg-none"
            >
              Play
            </a>
          ) : (
            ""
          )}
          <video
            onClick={(e) => {
              handleVideoPress(
                e,
                videoReftablet,
                setPlayingtablet,
                playingtablet
              );
            }}
            className="video__player d-block d-md-none d-lg-none"
            preload="auto"
            playsInline={true}
            autoPlay={true}
            muted={true}
            loop={true}
            ref={videoReftablet}
            poster={`${process.env.REACT_APP_TLD_IMAGE_URL}images/yellow-tv.jpg`}
            src={`${process.env.REACT_APP_TLD_IMAGE_URL}videos/StoriesVideo/WF-634944_Registry_dotTV_CONTAINER_1x1_15.mp4`}
          ></video>
          {playingtablet === false ? (
            <a
              href="#"
              onClick={(e) => {
                handleVideoPress(
                  e,
                  videoReftablet,
                  setPlayingtablet,
                  playingtablet
                );
              }}
              className="video-btn d-block d-md-none d-lg-none"
            >
              Play
            </a>
          ) : (
            ""
          )}
        </div>
      </div>
    </a>
  );
}

export default StoriesFeaturedvideo;
