import React, { useEffect, useRef, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import HeaderSearch from "../HeaderSearch";
import TvFooter from "../TvFooter/TvFooter";
import TvHeader from "../TvHeader/TvHeader";
import VolumeupdownAnimation from "../VolumeupdownAnimation";
import AvailableProgressBar from "../Available/AvailableProgressBar";
import "@splidejs/react-splide/css";
import { Fade } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import nextInterviewScroll from "../../Constants/homepageInterviewScroll";
import HomepageScroll from "./HomepageScroll";
import TvHomeWhosontv from "./TvHomeWhosontv";
import TvHomeVideopiller from "./TvHomeVideopiller";
import TvHomeWhyTv from "./TvHomeWhyTv";
import FeaturedStoriesSplide from "../common/FeaturedStoriesSplide";
import FooterSearchbar from "../FooterSearchbar";
import FooterScroll from "../FooterScroll";
import { useIsInViewport } from "../../Services/tvAPI";
import TvLoaderAnimation from "./TvLoaderAnimation";
import TvBanner from "./TvBanner";
import { UtmContext } from "../../Context/ContextProvider";
import {Helmet} from "react-helmet";

function TvHome() {
  //for UTM
  const search = useLocation().search;
  const { setUtmSource, setUtmCampaign, setUtmMedium, setUtmTerm } =
    useContext(UtmContext);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    if (new URLSearchParams(search).get("utm_source") !== null) {
      const utm_source = new URLSearchParams(search).get("utm_source");
      setUtmSource(utm_source);
    }
    if (new URLSearchParams(search).get("utm_campaign") !== null) {
      const utm_campaign = new URLSearchParams(search).get("utm_campaign");
      setUtmCampaign(utm_campaign);
    }
    if (new URLSearchParams(search).get("utm_medium") !== null) {
      const utm_medium = new URLSearchParams(search).get("utm_medium");
      setUtmMedium(utm_medium);
    }
    if (new URLSearchParams(search).get("utm_term") !== null) {
      const utm_term = new URLSearchParams(search).get("utm_term");
      setUtmTerm(utm_term);
    }
  }, []);
  //end UTM
  const ref2 = useRef(null);
  const isInViewport2 = useIsInViewport(ref2);

  const [footerSearchon, setFooterSearchon] = useState("headersearchshow");
  const [animationClass, setAnimationClass] = useState("page-loader");

  useEffect(() => {
    if (isInViewport2 === true) {
      setFooterSearchon("headersearchhide");
    } else {
      setFooterSearchon("headersearchshow");
    }
  }, [isInViewport2]);

  const bottomUpAnimation = keyframes`
    from { transform: translateY(50%); }
    to { transform: translateY(0) }
  `;
  const upBottomAnimation = keyframes`
    from { transform: translateY(-50%); }
    to { transform: translateY(0) }
  `;

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimationClass("page-loader animation-done");
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Helmet>
        <title>Search .TV Domains &#38; Bring Your Idea Online &#124; Get Your .TV Now</title> 
        <meta name="website" content="http://turnon.tv/"/>
        <meta name="description" content=".TV is the domain name for everything watchable. It's unique, short and says, 'watch me.' Get online with a .TV domain and share your content with the world."/>
      </Helmet>
      <div className={animationClass}>
        <TvLoaderAnimation />
      </div>
      <TvHeader />
      <TvBanner />
      <div className={footerSearchon + ` home-page-search`}>
        <HeaderSearch url={window.location.pathname} />
      </div>
      <Fade cascade>
        <HomepageScroll nextInterviews={nextInterviewScroll} />
      </Fade>
      <div className="section-row">
        <div className="container">
          <div className="mt-3 mt-md-4 mb-4 mb-md-5">
            <VolumeupdownAnimation />
          </div>
          <div className="domain-process-main-box px-3">
            <AvailableProgressBar currentstep={"ALL"} />
          </div>
        </div>
      </div>
      <TvHomeWhyTv />

      <TvHomeVideopiller />

      <TvHomeWhosontv />

      <div className="section-row overflow-hidden position-relative sliderwith-no-title">
        <div className="container">
          <FeaturedStoriesSplide />
        </div>
      </div>
      <FooterScroll />
      <div ref={ref2}>
        <FooterSearchbar url={window.location.pathname} />
      </div>
      <TvFooter />
    </>
  );
}

export default TvHome;
