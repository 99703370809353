import React, { useEffect, useState, useRef, useContext } from "react";
import FooterScroll from "../FooterScroll";
import FooterSearchbar from "../FooterSearchbar";
import TvFooter from "../TvFooter/TvFooter";
import TvHeader from "../TvHeader/TvHeader";
import FeaturedSite from "./FeaturedSite";
import FeaturedStories from "./FeaturedStories";
import StoriesFeaturedvideo from "./StoriesFeaturedvideo";
import StoriesGetfeatured from "./StoriesGetfeatured";
import StoriesPagetitle from "./StoriesPagetitle";
import StoriesQuote from "./StoriesQuote";
import StoriesShowcase from "./StoriesShowcase";
import HeaderSearch from "../HeaderSearch";
import { useIsInViewport } from "../../Services/tvAPI";
import { Fade, Zoom } from "react-awesome-reveal";
import { UtmContext } from "../../Context/ContextProvider";
import { Helmet } from "react-helmet";

function Stories(props) {
  //UTM
  const { activePage, setActivePage } = useContext(UtmContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    setActivePage(1);
  }, []);

  const ref2 = useRef(null);
  const isInViewport2 = useIsInViewport(ref2);

  const [footerSearchon, setFooterSearchon] = useState("headersearchshow");

  useEffect(() => {
    if (isInViewport2 === true) {
      setFooterSearchon("headersearchhide");
    } else {
      setFooterSearchon("headersearchshow");
    }
  }, [isInViewport2]);

  return (
    <>
      <Helmet>
        <title>Featured Stories &#38; Brands Building on .TV &#124; Discover .TV Domains</title> 
        <meta name="website" content="http://turnon.tv/stories"/>
        <meta name="description" content="Content creators around the world are finding ways to tap into their audience and get seen with a .TV domain. Get inspired and check out their stories here."/>
      </Helmet>
      <TvHeader />
      <div className={footerSearchon + ` home-page-search`}>
        <HeaderSearch url={window.location.pathname} />
      </div>
      <Zoom>
        <StoriesPagetitle />
      </Zoom>
      <FeaturedSite />
      <Fade>
        <StoriesFeaturedvideo />
      </Fade>
      <Fade>
        <FeaturedStories />
      </Fade>
      <Zoom>
        <StoriesQuote />
      </Zoom>
      <StoriesShowcase />
      <Zoom>
        <StoriesGetfeatured />
      </Zoom>
      <FooterScroll />
      <div ref={ref2}>
        <FooterSearchbar url={window.location.pathname} />
      </div>
      <TvFooter />
    </>
  );
}

export default Stories;
