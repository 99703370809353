import React, { useState, useEffect } from "react";
import axios from "axios";
import DomainAvailBox from "./DomainAvailBox";
import DomainUnavailBox from "./DomainUnavailBox";
import SuggestedDisplayBox from "../SuggestedDisplayBox";
import VolumeupdownAnimation from "../../VolumeupdownAnimation";
import AvailableProgressBar from "../../Available/AvailableProgressBar";

function DomainDIsplayBox({ domainAvail, domain }) {
  return (
    <>
      <div className="section-row pb-0">
        <AvailableProgressBar currentstep={"select"} />
      </div>
      <div className="section-row">
        <div className="container">
          <div className="small-container">
            <div className="row g-3">
              {Object.keys(domainAvail).length > 0 ? (
                <div className="col-md-6">
                  {domainAvail.data.status === "Available" ? (
                    <DomainAvailBox domain={domainAvail} />
                  ) : (
                    <DomainUnavailBox
                      domain={domainAvail}
                      whois={domainAvail.data.info}
                    />
                  )}
                </div>
              ) : (
                ""
              )}
              <div className="col-md-6">
                <SuggestedDisplayBox domain={domain} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-3">
        <VolumeupdownAnimation />
      </div>
    </>
  );
}

export default DomainDIsplayBox;
