import React from "react";
import VolumeupdownAnimation from "../VolumeupdownAnimation";

function PoliciesContent() {
  return (
    <>
      <div className="page-title-box">
        <div className="container">
          <div className="page-title px-4 px-md-0">            
            <h1>Policies</h1>
          </div>
          <div className="mt-3 mt-md-5 mb-4 mb-md-5">
            <VolumeupdownAnimation />
          </div>
          <div className="section-title text-center mb-2 mb-sm-3  mb-md-5 mt-4 mt-md-0">
            <h3>Please click below to view .TV Policies and Domain Management Information</h3>
          </div>
          <div className="policy-list table-responsive">
            <table className="table">
              <tbody>
                <tr>
                  <td>
                    <a aria-label='Registration Policy' target="_blank" href="policiespdf/Tv_policies/TV-POL-001-RegistrationPolicy-1.0.pdf">.TV Registration Policy</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a aria-label='Acceptable Use Policy' target="_blank" href="policiespdf/Tv_policies/TV-POL-002-AcceptableUsePolicy-1.0.pdf">.TV Acceptable Use Policy</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a aria-label='DNSSEC Practice Statement' target="_blank" href="policiespdf/Tv_policies/GDR-DNSSECPracticeStatement-tv-1.2.pdf">DNSSEC Practice Statement</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a aria-label='UDRP' target="_blank" href="https://www.icann.org/en/help/dndr/udrp">UDRP</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a aria-label='FORUM' target="_blank" href="https://www.adrforum.com/domain-dispute/cctld">FORUM</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a aria-label='Whois Data Reminder Policy' target="_blank" href="https://www.icann.org/en/resources/registrars/consensus-policies/wdrp">Whois Data Reminder Policy</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a aria-label='Transfer Policy' target="_blank" href="https://www.icann.org/en/resources/registrars/transfers">Transfer Policy</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a aria-label='AGP Limits Policy' target="_blank" href="https://www.icann.org/en/resources/registries/agp/agp-policy-17dec08-en.htm">AGP Limits Policy</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default PoliciesContent;
