import React from "react";

function InstagramLogo(props) {
  return (
    <a href="https://www.instagram.com/tvdomain" target={"_blank"} aria-label="Instagram">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 50 50"
        style={{ enableBackground: "new 0 0 50 50" }}
        xmlSpace="preserve"
      >
        <path
          className="st0"
          d="M25,16.67c-4.6,0-8.32,3.73-8.32,8.32s3.72,8.32,8.32,8.32s8.32-3.73,8.32-8.32C33.3,20.4,29.59,16.68,25,16.67z M38.34,14.67c-1.66-0.01-3.01-1.36-3.02-3.02c0.01-1.66,1.36-3,3.02-3c0.8-0.01,1.57,0.3,2.14,0.87c0.56,0.57,0.86,1.35,0.84,2.15C41.31,13.32,39.99,14.66,38.34,14.67z M25,37.82c-5.21,0-9.9-3.15-11.88-7.96s-0.85-10.35,2.85-14.01c3.7-3.66,9.25-4.72,14.05-2.69c4.79,2.04,7.88,6.76,7.82,11.97C37.79,32.17,32.04,37.85,25,37.82z M44.99,12.29c-0.46-3.64-3.2-6.57-6.8-7.3c-2.27-0.41-4.57-0.59-6.87-0.52c-4.2,0-8.42,0-12.64,0C16.54,4.46,14.41,4.64,12.31,5c-3.36,0.42-6.14,2.81-7.05,6.07c-0.53,2.1-0.76,4.26-0.67,6.42c0,3.4,0,6.8,0,10.19c-0.07,3.09,0.05,6.19,0.35,9.27c0.17,3.93,2.99,7.23,6.85,8.02c2.28,0.42,4.6,0.58,6.92,0.5c4.2,0,8.36,0,12.49,0c2.17,0.03,4.33-0.14,6.47-0.5c2.78-0.36,5.2-2.07,6.47-4.57c0.75-1.65,1.15-3.44,1.17-5.25c0.2-3.4,0.17-6.82,0.15-10.22c0-2.1,0-4.2,0-6.3C45.45,16.52,45.29,14.4,44.99,12.29z M49.98,32.86c0.08,2.66-0.26,5.32-1.02,7.87c-1.46,4.52-5.31,7.86-9.99,8.67C36.01,49.92,33,50.11,30,49.98c-4.32,0-8.62,0-12.92,0c-2.66,0.09-5.32-0.26-7.87-1.02c-4.53-1.46-7.88-5.31-8.7-9.99c-0.4-2.28-0.56-4.59-0.5-6.9c0-5,0-9.99,0-14.99c-0.08-2.65,0.29-5.29,1.07-7.82c1.45-4.53,5.3-7.88,9.99-8.67C14.03,0.1,17.02-0.1,20,0.01c1.67,0,3.35,0,5,0c3.4,0,6.82,0,10.22,0c3.17,0.03,6.26,0.99,8.89,2.75c2.97,2.1,4.93,5.34,5.42,8.94c0.37,2.75,0.52,5.52,0.45,8.3C49.98,24.22,49.98,28.54,49.98,32.86z"
        />
      </svg>
    </a>
  );
}

export default InstagramLogo;
