import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import AccreditedRegistrars from "./Components/AccreditedRegistrars/AccreditedRegistrars";
import Search from "./Components/Search/Search";
import Stories from "./Components/Stories/Stories";
import TvHome from "./Components/TvHome/TvHome";
import Interview from "./Components/Interview/Interview";
import InterviewDetails from "./Components/Interview/InterviewDetails";
import Faq from "./Components/Faq/Faq";
import About from "./Components/About/About";
import ContactUs from "./Components/ContactUs/ContactUs";
import Available from "./Components/Available/Available";
import Policies from "./Components/Policies/Policies";
import NotFound from "./Components/NotFound/NotFound";

function AppWithRouterAccess(props) {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<TvHome />} />
        <Route path="/stories" element={<Stories />} />
        <Route
          path="/accreditedregistrars"
          element={<AccreditedRegistrars />}
        />
        <Route path="/search" element={<Search />} />
        <Route path="/available" element={<Available />} />
        <Route path="/whosontv" element={<Interview />} />
        {
          <Route
            path="/whosontv/:interview_name"
            element={<InterviewDetails />}
          />
        }
        <Route path="/faq" element={<Faq />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/policies" element={<Policies />} />
        <Route path="*" element={<Navigate to="/page-not-found" />} />
        <Route path="/page-not-found" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default AppWithRouterAccess;
