import React, { useEffect, useState, useContext } from "react";
import { getAllFeatureChannelPartners } from "../../Services/tvAPI";
import { UtmContext } from "../../Context/ContextProvider";

function AvailableFeatureRegistrar({ tld_id, domain }) {
  const { utmSource, utmCampaign, utmMedium, utmTerm } = useContext(UtmContext);
  const [allFeaturedChannelPartner, setAllFeaturedChannelPartner] = useState(
    []
  );

  const arr = domain.split(".");
  const domain_without_tld = arr[0];

  useEffect(() => {
    if (tld_id !== null || tld_id === "") {
      const allFeaturedChannelPartner = async () => {
        let data = await getAllFeatureChannelPartners();
        setAllFeaturedChannelPartner(data);
      };
      allFeaturedChannelPartner();
    }
  }, []);

  let timeout;
  function timeout_init(param) {
    window.dataLayer.push({
      event: "Featured Registrar Select Click",
      page: {
        url: window.location.href,
        title: "TurnOn.tv",
      },
    });

    timeout = setTimeout(() => {
      timeout_trigger(param);
    }, 3000);
  }

  function timeout_trigger(param) {
    // let newTab = window.open("", "_blank");
    // newTab.location.href = param;
    window.location = param;
  }

  function timeout_clear() {
    clearTimeout(timeout);
  }

  return (
    <>
      {Object.keys(allFeaturedChannelPartner).length > 0 ? (
        <>
          <div className="trusted-partner-main-list">
            <div className="row g-3 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
              {Object.keys(allFeaturedChannelPartner).map(function (
                featurekey,
                index
              ) {
                let feature_url =
                  allFeaturedChannelPartner[featurekey].featured_url;
                let feature_url_replace = feature_url.replaceAll(
                  "example",
                  domain_without_tld
                );

                if (utmSource !== "") {
                  feature_url_replace = feature_url_replace + "&utm_source=" + utmSource;
                }

                if (utmMedium !== "") {
                  feature_url_replace = feature_url_replace + "&utm_medium=" + utmMedium;
                }

                if (utmCampaign !== "") {
                  feature_url_replace = feature_url_replace + "&utm_campaign=" + utmCampaign;
                }

                if (utmTerm !== "") {
                  feature_url_replace = feature_url_replace + "&utm_term=" + utmTerm;
                }

                return (
                  <div className="col" key={index}>
                    <div className="card text-md-center">
                      <figure>
                        <img
                          src={
                            process.env.REACT_APP_API_URL +
                            "" +
                            allFeaturedChannelPartner[featurekey].logo_url
                          }
                          alt={allFeaturedChannelPartner[featurekey].icann_short_name}
                        />
                      </figure>
                      <p>{allFeaturedChannelPartner[featurekey].icann_short_name}</p>
                      <a
                        href={feature_url_replace}
                        className="btn btn-sm btn-primary border-0"
                        data-bs-toggle="modal"
                        data-bs-target={"#domainModal" + index}
                        onClick={() => timeout_init(feature_url_replace)}
                      >
                        Select
                      </a>
                    </div>
                    <div
                      className="domain-model modal fade"
                      id={"domainModal" + index}
                      tabIndex="-1"
                      aria-labelledby="domainModalLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-body text-center">
                            <h3>
                              You’ve selected&nbsp;
                              {
                                allFeaturedChannelPartner[featurekey].icann_short_name
                              }
                            </h3>
                            <p>
                              Sit tight, we’ll redirect you to complete your
                              purchase.
                            </p>
                            <p>
                              Or you may&nbsp;
                              <a
                                href="/#"
                                data-bs-dismiss="modal"
                                onClick={() => timeout_clear()}
                              >
                                cancel your selection
                              </a>
                              &nbsp;and return to the search results.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default AvailableFeatureRegistrar;
