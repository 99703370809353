import React, { useState } from "react";

import VolumeupdownAnimation from "../VolumeupdownAnimation";
import ShowcaseList from "../../Constants/Stories/ShowcaseList";
import { useNavigate } from "react-router-dom";
import { Fade, Slide } from "react-awesome-reveal";

function StoriesShowcase(props) {
  const [next, setNext] = useState(6);
  const handleMoreImage = () => {
    setNext(next + 6);
  };

  const navigate = useNavigate();
  const handleLearnMore = (e, link) => {
    if (link === "") {
      e.preventDefault();
    } else {
      navigate(`/${link}`);
    }
  };

  return (
    <div className="section-row overflow-hidden">
      <div className="container">
        <Fade cascade>
          <div className="section-title text-center mb-4 mb-md-5 pb-md-3">
            <div className="pb-3 pb-md-5">
              <div className="mb-4">
                <VolumeupdownAnimation />
              </div>
            </div>
            <h2 className="m-0">showcase</h2>
            <p>Here are some of our favorite trending .TV sites:</p>
          </div>
        </Fade>
        <div className="showcase-list">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="row">
                {ShowcaseList?.slice(0, next)?.map((item, index) => {
                  return (
                    <div className="col-md-6 mb-4" key={index}>
                      <Fade cascade>
                        <div className="showcase-box">
                          <a aria-label='Show case'
                            onClick={(e) => {
                              handleLearnMore(e, item.link);
                            }}
                          >
                            <figure>
                              <img
                                className="w-100"
                                src={`${process.env.REACT_APP_TLD_IMAGE_URL}${item.image}`}
                                alt="showcase image"
                              />
                            </figure>
                          </a>
                        </div>
                      </Fade>
                    </div>
                  );
                })}
                {next < ShowcaseList?.length && (
                  <div className="d-md-block text-center pt-2">
                    <a aria-label='More Showcase' className="btn btn-primary" onClick={handleMoreImage}>
                      LOAD MORE
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StoriesShowcase;
