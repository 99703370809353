import React from "react";

function LinkedinLogo(props) {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.735 1.84783C3.735 2.86857 2.9025 3.69565 1.875 3.69565C0.8475 3.69565 0.015 2.86857 0.015 1.84783C0.015 0.827826 0.8475 0 1.875 0C2.9025 0 3.735 0.827826 3.735 1.84783ZM3.75 5.17391H0V17H3.75V5.17391ZM9.7365 5.17391H6.0105V17H9.73725V10.792C9.73725 7.3403 14.259 7.05796 14.259 10.792V17H18V9.51187C18 3.68752 11.3085 3.89965 9.7365 6.76674V5.17391Z"
        fill="white"
      />
    </svg>
  );
}

export default LinkedinLogo;
