import React from "react";
import VolumeupdownAnimation from "../VolumeupdownAnimation";
import founderQuotesData from "../../Constants/Stories/founderQuoteData";
function StoriesQuote() {

  return (
    <div className="section-row">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="founder-quote text-center mt-md-4">
              <div className="pb-3 pb-md-5">
                <div className="mb-4 mb-md-5">
                  <VolumeupdownAnimation />
                </div>
              </div>
              <h2 className="mb-3">{founderQuotesData.quote}</h2>
              <p>
                <a
                  href={founderQuotesData.link}                  
                  className="btn btn-primary"
                >
                  READ MORE
                </a>
              </p>
              <p>
                <strong>{founderQuotesData.founderName},</strong> <br />
                Founder,
                <a
                  href={founderQuotesData.websiteLink}
                  target = "_blank"
                >
                  {founderQuotesData.OrganisationName}
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StoriesQuote;
