import "./App.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/style.css";
import "./css/responsive.css";
import AppWithRouterAccess from "./AppWithRouterAccess";
import ContextProvider from "./Context/ContextProvider";

require("intersection-observer");

function App() {
  return (
    <ContextProvider>
      <AppWithRouterAccess />
    </ContextProvider>
  );
}

export default App;
