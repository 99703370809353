import React from "react";
import UpDownIcon from "../Logos/UpDownIcon";
import AvailableDrawerRegistrar from "./AvailableDrawerRegistrar";
import AvailableFeatureRegistrar from "./AvailableFeatureRegistrar";
import VolumeupdownAnimation from "../VolumeupdownAnimation";

function Registrar({ tld_id, domain }) {
  return (
    <>
      <div className="my-3">
        <VolumeupdownAnimation />
      </div>
      <div className="section-row">
        <div className="container">
          <div className="small-container">
            <div className="p-0 trusted-partner-card-box card-body text-center">
              <div className="section-title">
                <h2 className="d-none d-md-block">
                  Select a trusted retail partner.
                </h2>
                <h2 className="d-md-none">
                  Select a trusted retail partner to continue.
                </h2>
                <p className="d-none d-md-block">
                  We’ll redirect you to their site to complete your purchase.
                </p>
                <p className="d-md-none">
                  Follow the link to a partner site to purchase your domain.
                </p>
              </div>
              <div className="pt-2 trusted-partner-list">
                <AvailableFeatureRegistrar tld_id={tld_id} domain={domain} />
                <div className="trusted-all-options-btn">
                  <a
                    className="trusted-options-triger collapsed"
                    data-bs-toggle="collapse"
                    href="#trustedPartnerAll1"
                    role="button"
                    aria-expanded="false"
                    aria-controls="trustedPartnerAll1"
                  >
                      See More Retail Partners
                      <UpDownIcon />
                  </a>
                </div>
                <div className="collapse" id="trustedPartnerAll1">
                  <AvailableDrawerRegistrar tld_id={tld_id} domain={domain} />
                </div>
              </div>
              <div className="retailers-note mt-5 p-4 text-center">
                <h4 className="mb-2 text-uppercase">
                  <strong>Retailers are listed alphabetically.</strong>
                </h4>
                <p className="m-0">
                There are many providers that offer the .tv domain. Please check with your preferred provider or one of the above to register today. To see a list of all Accredited Registrars, please <a  href="/accreditedregistrars">click here</a>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Registrar;
