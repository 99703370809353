import React from "react";
import featuredSiteData from "../../Constants/Stories/featuredSiteData";
import { useNavigate } from "react-router-dom";
import { Slide } from "react-awesome-reveal";

function FeaturedSite(props) {
  const navigate = useNavigate();
  const handleLearnMore = (e, link) => {
    if (link === "") {
      e.preventDefault();
    } else {
      e.preventDefault();
      navigate(`${link}`);
    }
  };
  return (
    <>
      <div className="section-row devider-box py-2 py-sm-4 py-md-5"></div>
      <div className="section-row">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <Slide cascade direction="left">
                <figure>
                  <img
                    src={`${process.env.REACT_APP_TLD_IMAGE_URL}images/interviewthumbnails/BoardroomLogo.png`}
                    alt="BoardroomLogo"
                  />
                </figure>
              </Slide>
            </div>
            <div className="col-lg-6">
              <Slide cascade direction="right">
                <div className="hignlight-story-box">
                  <h2>{featuredSiteData.Name}</h2>
                  <p>{featuredSiteData.Description}</p>
                  <a href={featuredSiteData.link} className="btn btn-primary">
                    Learn More{" "}
                    <span className="no-show-label">
                      {featuredSiteData.Name}
                    </span>
                  </a>
                </div>
              </Slide>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FeaturedSite;
