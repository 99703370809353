import React from "react";
import StoryMarque from "../common/MarqueAnimation";

function HomepageScroll(props) {
  return (
    <div className="highlight-text">
      <div className="top-line-box">
        <span className="line-left"></span>
        <span className="line-right"></span>
      </div>
      <h2>
        <StoryMarque
          velocity={"-0.3"}
          title={[
            <>
              {props.nextInterviews.map((interview, index) => {
                return (
                  <span key={index}>
                    BRING YOURS ONLINE&nbsp;
                    <a aria-label="Interview Link" href={interview.link}>
                      <span className="highlight-video">
                        {" "}
                        <h6>
                          {" "}
                          Coming up next <br />
                          {interview.name}
                        </h6>{" "}
                        <img
                          src={`${process.env.REACT_APP_TLD_IMAGE_URL}${interview.image}`}
                          alt="highlight image"
                        ></img>
                      </span>{" "}
                    </a>
                  </span>
                );
              })}
            </>,
          ]}
        />
      </h2>
    </div>
  );
}

export default HomepageScroll;
