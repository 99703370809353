import React, { useEffect, useState, useRef, useContext } from "react";
import HeaderSearch from "../HeaderSearch";
import TvFooter from "../TvFooter/TvFooter";
import TvHeader from "../TvHeader/TvHeader";
import ContactMapForm from "./ContactMapForm";
import TvLogo from "../Logos/TvLogo";
import VolumeupdownAnimation from "../VolumeupdownAnimation";
import { Zoom } from "react-awesome-reveal";
import FooterScroll from "../FooterScroll";
import FooterSearchbar from "../FooterSearchbar";
import { useIsInViewport } from "../../Services/tvAPI";
import { UtmContext } from "../../Context/ContextProvider";
import { Helmet } from "react-helmet";

function ContactUs() {
  const { setActivePage } = useContext(UtmContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    setActivePage(4);
  }, []);

  const ref2 = useRef(null);
  const isInViewport2 = useIsInViewport(ref2);

  const [footerSearchon, setFooterSearchon] = useState("headersearchshow");

  useEffect(() => {
    if (isInViewport2 === true) {
      setFooterSearchon("headersearchhide");
    } else {
      setFooterSearchon("headersearchshow");
    }
  }, [isInViewport2]);

  return (
    <>
      <Helmet>
        <title>Contact Us &#38; Let Us Know How We Can Help &#124; .TV Domain</title> 
        <meta name="website" content="http://turnon.tv/contact"/>
        <meta name="description" content="Get in touch with the .TV domain team to ask a question or send a note. Relevant contact details provided here. We would love to hear from you."/>
      </Helmet>
      <TvHeader />
      <div className={footerSearchon + ` home-page-search`}>
        <HeaderSearch url={window.location.pathname} />
      </div>
      <Zoom>
        <div className="page-title-box d-block d-md-none">
          <div className="container">
            <div className="page-title">
              <figure>
                <TvLogo />
              </figure>
              <h1>
                GET IN <br />
                TOUCH
              </h1>
              <p>
                Use this form to send us a note, ask questions, or get in touch.
              </p>
              <div className="mt-5 mb-0">
                <VolumeupdownAnimation />
              </div>
            </div>
          </div>
        </div>
      </Zoom>
      <ContactMapForm />
      <FooterScroll />
      <div ref={ref2}>
        <FooterSearchbar url={window.location.pathname} />
      </div>
      <TvFooter />
    </>
  );
}

export default ContactUs;
