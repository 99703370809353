import React, { useEffect, useState, useRef, useContext } from "react";
import TvFooter from "../TvFooter/TvFooter";
import TvHeader from "../TvHeader/TvHeader";
import FaqBanner from "./FaqBanner";
import FaqQuestionAnswers from "./FaqQuestionAnswers";
import HeaderSearch from "../HeaderSearch";
import { Zoom } from "react-awesome-reveal";
import FooterScroll from "../FooterScroll";
import FooterSearchbar from "../FooterSearchbar";
import { useIsInViewport } from "../../Services/tvAPI";
import { UtmContext } from "../../Context/ContextProvider";
import { Helmet } from "react-helmet";

function Faq() {
  const { setActivePage } = useContext(UtmContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    setActivePage(3);
  }, []);

  const ref2 = useRef(null);
  const isInViewport2 = useIsInViewport(ref2);

  const [footerSearchon, setFooterSearchon] = useState("headersearchshow");

  useEffect(() => {
    if (isInViewport2 === true) {
      setFooterSearchon("headersearchhide");
    } else {
      setFooterSearchon("headersearchshow");
    }
  }, [isInViewport2]);
  return (
    <>
      <Helmet>
        <title>Answers to Frequently Asked Questions &#124; .TV Domain FAQs</title> 
        <meta name="website" content="http://turnon.tv/faq"/>
        <meta name="description" content="Find answers to commonly asked questions about .TV domains and definitions of relevant industry terms."/>
      </Helmet>
      <TvHeader />
      <div className={footerSearchon + ` home-page-search`}>
        <HeaderSearch url={window.location.pathname} />
      </div>
      <Zoom>
        <FaqBanner />
      </Zoom>
      <FaqQuestionAnswers />
      <FooterScroll />
      <div ref={ref2}>
        <FooterSearchbar url={window.location.pathname} />
      </div>
      <TvFooter />
    </>
  );
}

export default Faq;
