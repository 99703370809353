import React from "react";
import VolumeupdownAnimation from "../VolumeupdownAnimation";

import "@splidejs/react-splide/css";
import FeaturedStoriesSplide from "../common/FeaturedStoriesSplide";
import MarqueAnimation from "../common/MarqueAnimation";
function FeaturedStories(props) {
  return (
    <>
      <div className="highlight-text">
        <div className="top-line-box">
          <span className="line-left"></span>
          <span className="line-right"></span>
        </div>
        <h2>
          <MarqueAnimation
            velocity={"-0.2"}
            title={
              "CREATE UPLOAD POST STREAM SHARE CONNECT"
            }
          />
        </h2>
      </div>

      <div className="section-row overflow-hidden position-relative">
        <div className="container">
          <div className="section-title text-center mt-md-4 mb-md-5 mb-3">
            <div className="mb-4 mb-md-5">
              <VolumeupdownAnimation />
            </div>
            <h2>FEATURED stories</h2>
            <p>Check out interviews with a few of our favorite .TV creators.</p>
          </div>
          <FeaturedStoriesSplide />
        </div>
      </div>
    </>
  );
}

export default FeaturedStories;
