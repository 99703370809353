import React from "react";
import VolumeupdownAnimation from "../VolumeupdownAnimation";
import { Fade, Zoom, Slide, Roll } from "react-awesome-reveal";
import { Reveal } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

function TvHomeWhyTv(props) {
  const customAnimation = keyframes`
    from { width: 100%; }
    to { width:  6px; }
  `;
  const autoType = keyframes`
    from { width: 0; }
    to { width:  100%; }
  `;
  return (
    <div className="section-row why-tv-section">
      <div className="container">
        <div className="mb-5 mb-md-5 pb-md-5">
          <VolumeupdownAnimation />
        </div>
        <div className="section-title px-3 text-center pt-3 pt-md-0">
          <h2 className="d-inline-block h1 text-lime">
            <Fade className="autotype-text" keyframes={autoType}>
              WHY .TV?
            </Fade>
          </h2>
          <Fade delay={1}>
            <p>
              We live in a multi-screen world where content is being created and
              consumed every second of every day. The .TV domain is perfect for
              sharing your creativity with the world — whether you’re a Blogger,
              a YouTuber, a Twitch Streamer, an Instagram Influencer, or a
              TikTok Star.{" "}
            </p>
          </Fade>
        </div>
      </div>
      <div className="section-row pb-0 mt-md-5 mt-0 why-tv-list">
        <div className="why-tv-box text-center text-md-start">
          <Reveal className="bg-color-piller" keyframes={customAnimation}>
            <span
              className="bg-color-piller"
              style={{ backgroundColor: "#F5FF03" }}
            ></span>
          </Reveal>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-8 col-lg-7 col-md-7">
                <h2>It says “watch me”</h2>
              </div>
              <div className="col-xl-4 col-lg-5 col-md-5">
                <div className="why-tv-details">
                  <p>
                    From smartphones to tablets to laptops and old-school
                    television, .TV is perfect for our multi-screen world.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="why-tv-box text-center text-md-start">
          <Reveal className="bg-color-piller" keyframes={customAnimation}>
            <span
              className="bg-color-piller"
              style={{ backgroundColor: "#FF00B8" }}
            ></span>
          </Reveal>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-8 col-lg-7 col-md-7">
                <h2>It’s short and spells itself</h2>
              </div>
              <div className="col-xl-4 col-lg-5 col-md-5">
                <div className="why-tv-details">
                  <p>
                    It’s confusion-free, memorable, and lets everyone know it’s
                    all about content.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="why-tv-box text-center text-md-start">
          <Reveal className="bg-color-piller" keyframes={customAnimation}>
            <span
              className="bg-color-piller"
              style={{ backgroundColor: "#5CFFFA" }}
            ></span>
          </Reveal>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-8 col-lg-7 col-md-7">
                <h2>It’s unique</h2>
              </div>
              <div className="col-xl-4 col-lg-5 col-md-5">
                <div className="why-tv-details">
                  <p>
                    If you want to stand out from the crowd and get noticed, .TV
                    is the domain for you.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TvHomeWhyTv;
