import React from "react";

function InterviewMarqueeImage(props) {
  return (
    <div className="post-main-img">
      <figure className="m-0">
        <img
          className="d-none d-md-none d-lg-block"
          src={`${process.env.REACT_APP_TLD_IMAGE_URL}images${props.image.dekstop}`}
          alt="post image"
        />

        <img
          className="d-none d-md-block d-lg-none"
          src={`${process.env.REACT_APP_TLD_IMAGE_URL}images${props.image.mobile}`}
          alt="post image"
        />

        <img
          className="d-block d-md-none d-lg-none"
          src={`${process.env.REACT_APP_TLD_IMAGE_URL}images${props.image.tablet}`}
          alt="post image"
        />
      </figure>
    </div>
  );
}

export default InterviewMarqueeImage;
