import React from "react";
import { useEffect } from "react";
import TvFooter from "../TvFooter/TvFooter";
import TvHeader from "../TvHeader/TvHeader";
import "./NotFound.css";
import notFoundImg from "./404.svg";
import { Zoom } from "react-awesome-reveal";
import VolumeupdownAnimation from "../VolumeupdownAnimation";
import { Helmet } from "react-helmet";

function NotFound() {
  const usePageMeta = () => {
    useEffect(() => {
      document
        .querySelector("meta[name='prerender-status-code']")
        .setAttribute("content", "404");
    }, []);
  };
  return (
    <>
      <Helmet>
        <title>Page Cannot Be Found &#124; .TV Domain</title> 
        <meta name="website" content="http://turnon.tv/page-not-found"/>
        <meta name="description" content="The resource requested could not be found on this server. Please search again."/>
      </Helmet>
      {usePageMeta()}
      <TvHeader />
      <Zoom>
        <div className="notFoundSection">
          <div className="container">
            <h1 className="pageTitle">
              <img src={notFoundImg} alt="" />
              <span>Page Not Found</span>
            </h1>
            <p>The resource requested could not be found on this server!</p>
            <a href="/" className="btn btn-primary">
              Back To Home
            </a>
            <div className="mt-5">
              <VolumeupdownAnimation />
            </div>
          </div>
        </div>
      </Zoom>
      <TvFooter />
    </>
  );
}

export default NotFound;
