import React from "react";
import GetIt from "../Buttons/GetIt";

function SuggestedDisplayBox({ domain }) {
  let actualDomain = domain.NameWithoutExtension + "." + domain.Extension;

  return (
    <div className="h-100 d-flex flex-column justify-content-center text-md-center py-4 py-md-5 px-4 tld-info-box">
      <h3>
        {domain.NameWithoutExtension}
        <strong>.{domain.Extension}</strong>
      </h3>

      <div className="text-md-center">
        <GetIt actualDomain={actualDomain} />
      </div>
    </div>
  );
}

export default SuggestedDisplayBox;
