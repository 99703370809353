import React from "react";
import MarqueAnimation from "./common/MarqueAnimation";

function FooterScroll(props) {
  return (
    <div
      className="highlight-text"
      style={{ backgroundColor: "#03F500", color: "#000000" }}
    >
      <div className="top-line-box">
        <span className="line-left"></span>
        <span className="line-right"></span>
      </div>
      <h2>
        <MarqueAnimation velocity={"-1"} title={"BRING YOURS ONLINE"} />
      </h2>
    </div>
  );
}

export default FooterScroll;
