const fmenu = [
  ["Home", "/", ""],
  ["About", "/about", ""],
  ["WHOIS", "https://whois.nic.tv", "_blank"],
  ["Policies", "/policies", ""],
  ["Accredited Registrars", "/accreditedregistrars", ""],
  ["FAQ", "/faq", ""],
  ["Contact", "/contact", ""],
];
export default fmenu;
