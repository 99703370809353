import React, { useEffect, useState, useRef, useContext } from "react";
import HeaderSearch from "../HeaderSearch";
import TvFooter from "../TvFooter/TvFooter";
import TvHeader from "../TvHeader/TvHeader";
import AboutBanner from "./AboutBanner";
import AboutContent from "./AboutContent";
import { Zoom } from "react-awesome-reveal";
import FooterScroll from "../FooterScroll";
import FooterSearchbar from "../FooterSearchbar";
import { useIsInViewport } from "../../Services/tvAPI";
import { UtmContext } from "../../Context/ContextProvider";
import {Helmet} from "react-helmet";

function About() {
  const { setActivePage } = useContext(UtmContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    setActivePage(2);
  }, []);
  const ref2 = useRef(null);
  const isInViewport2 = useIsInViewport(ref2);

  const [footerSearchon, setFooterSearchon] = useState("headersearchshow");

  useEffect(() => {
    if (isInViewport2 === true) {
      setFooterSearchon("headersearchhide");
    } else {
      setFooterSearchon("headersearchshow");
    }
  }, [isInViewport2]);

  return (
    <>
      <Helmet>
        <title>What is a .TV Domain&#63; Learn About the .TV Top-Level Domain</title> 
        <meta name="website" content="http://turnon.tv/about"/>
        <meta name="description" content="Beyond representing streaming content, the .TV domain is the country code top-level domain for Tuvalu, an independent island nation in the South Pacific Ocean."/>
      </Helmet>
      <TvHeader />
      <div className={footerSearchon + ` home-page-search`}>
        <HeaderSearch url={window.location.pathname} />
      </div>
      <Zoom>
        <AboutBanner />
      </Zoom>
      <AboutContent />
      <FooterScroll />
      <div ref={ref2}>
        <FooterSearchbar url={window.location.pathname} />
      </div>
      <TvFooter />
    </>
  );
}

export default About;
