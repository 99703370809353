import React, { useEffect, useState,useContext } from "react";
import TvFooter from "../TvFooter/TvFooter";
import TvHeader from "../TvHeader/TvHeader";
import SearchBox from "./SearchBox";
import { Navigate, useLocation } from "react-router-dom";
import DomainDisplayBox from "./Domaindisplaybox/DomainDisplayBox";
import { getAvailabilityStatus, getSuggestedDomain,getTrackSearchAPI } from "../../Services/tvAPI";
import TldList from "./TldList.js/TldList";
import VolumeupdownAnimation from "../VolumeupdownAnimation";
import { UtmContext } from "../../Context/ContextProvider";
import { Helmet } from "react-helmet";

function Search(props) {
  //UTM
  const {
    domainToTrack,
    setDomainToTrack,
    utmSource,
    utmCampaign,
    utmMedium,
    utmTerm,
  } = useContext(UtmContext);
  //UTM

  const [domainAvail, setDomainAvail] = useState({});
  const [suggestedDomainData, setSuggestedDomainData] = useState([]);
  const [suggestedDomainDisplaybox, setSuggestedDomainDisplaybox] = useState({});
  const [suggestedDomainList, setSuggestedDomainList] = useState([]);
  const search = useLocation().search;
  let domain = "";
  if (new URLSearchParams(search).get("domain") !== null) {
    const domainWithChar = new URLSearchParams(search).get("domain");
    domain = domainWithChar.replace(/[&\/\\#,+()$~%.'":*?<>{}^]/g, "");
  }
  const domainName = domain.split(".");
  var websiteUrl = `http://turnon.tv/search?domain=${domain}`;

  useEffect(() => {
    window.scrollTo(0, 0);

    window.dataLayer.push({
      event: "domain search click",
      page: {
        url: window.location.href,
        title: "TurnOn.tv",
      },
    });
  }, []);

  useEffect(() => {
    if (domain !== "") {
      const tldId = 0;
      const pageRef = window.location.origin;   

      let getData = async () => {
        let data = await getAvailabilityStatus(domain);
        setDomainAvail(data);

        //Search tracking
        if(domainToTrack !== domainName[0]){
          let availability = "";
          if(data.data.status  === 'Available'){
            availability= 'a'
          }else{
            availability= 'r'
          }
          setDomainToTrack(domainName[0]);
          const searchTrack = async () => {
            let trackResponse = await getTrackSearchAPI(
              domainName[0],
              tldId,
              pageRef,
              utmSource,
              utmCampaign,
              utmMedium,
              utmTerm,
              availability
            );
          };
          searchTrack(); 
        }
        //Search tracking     
      };
      getData();
    }
  }, [domain]);

  useEffect(() => {
    if (Object.keys(domainAvail).length > 0) {
      let getData = async () => {
        const data = await getSuggestedDomain(domain);
        const suggestedData = [...data.data.Domains];
        suggestedData.map((domain, index) => {
          if (domain.DomainName === domainAvail.data.domain.toLowerCase()) {
            suggestedData.splice(index, 1);
          }
          return suggestedData;
        });

        setSuggestedDomainData(suggestedData);
      };
      getData();
    }
  }, [domainAvail]);

  useEffect(() => {
    if (suggestedDomainData.length > 0) {
      const suggestedDomainRemove = [...suggestedDomainData];
      const suggestedDomainToDisplay = suggestedDomainRemove.shift();
      setSuggestedDomainDisplaybox(suggestedDomainToDisplay);
      setSuggestedDomainList(suggestedDomainRemove);
    }
  }, [suggestedDomainData]);

  return (
    <>
      {domain ? (
        <>
          <Helmet>
            <title>Register a Memorable .TV Domain Name &#124; Find Availability</title> 
            <meta name="website" content={websiteUrl}/>
            <meta name="description" content="Find your available .TV domain name and get a memorable and brandable URL for your website. Register your .TV domain through any of our trusted retail partners."/>
          </Helmet>
          <TvHeader />
          <SearchBox domain={domain} />
          {Object.keys(suggestedDomainDisplaybox).length > 0 ? (
            <>
              <DomainDisplayBox
                domainAvail={domainAvail}
                domain={suggestedDomainDisplaybox}
              />
              <TldList suggestedDomain={suggestedDomainList} />
            </>
          ) : (
            <div className="my-5 py-5">
              <VolumeupdownAnimation />
            </div>
          )}

          <TvFooter />
        </>
      ) : (
        <Navigate to={"/"} />
      )}
    </>
  );
}

export default Search;
