import React from "react";

function TvLogo(props) {
  return (
    
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 500 244"
          style={{ enableBackground: "new 0 0 500 244" }}
          xmlSpace="preserve"
        >
          <g>
            <polygon
              className="st0"
              points="40.96,0.68 40.96,86.79 120.18,86.79 120.18,243.49 202.1,243.49 202.1,86.12 241.03,86.12 241.03,243.16 323.97,243.16 500,0.68 396.41,0.68 322.95,102.33 322.95,0"
            />
            <ellipse
              className="st0"
              cx="41.05"
              cy="200.6"
              rx="41.05"
              ry="43.4"
            />
          </g>
        </svg>
  );
}

export default TvLogo;
