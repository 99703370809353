import React from 'react'

function UpDownIcon() {
  return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 20 20" style={{ enableBackground: "new 0 0 20 20"}} xmlSpace="preserve">
        <path d="M10,7.18l8.11,7.89c0.21,0.2,0.49,0.31,0.78,0.31c0.29,0,0.58-0.11,0.78-0.31c0.21-0.2,0.32-0.47,0.32-0.76c0-0.28-0.12-0.56-0.32-0.76l-8.87-8.59c-0.1-0.11-0.23-0.19-0.36-0.25c-0.14-0.06-0.29-0.09-0.44-0.09l0,0l0,0c-0.29,0-0.58,0.11-0.78,0.32l-8.89,8.62c-0.1,0.1-0.18,0.22-0.24,0.35C0.03,14.03,0,14.17,0,14.31s0.03,0.28,0.08,0.41c0.06,0.13,0.14,0.25,0.24,0.35c0.1,0.1,0.23,0.18,0.36,0.23c0.13,0.05,0.28,0.08,0.42,0.08s0.29-0.03,0.42-0.08c0.13-0.05,0.26-0.13,0.36-0.23L10,7.18"/>
    </svg>
  )
}

export default UpDownIcon