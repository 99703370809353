import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UtmContext } from "../../Context/ContextProvider";
import { getTrackSearchAPI } from "../../Services/tvAPI";

function GetIt({ actualDomain }) {
  const navigate = useNavigate();
  const { utmSource, utmCampaign, utmMedium, utmTerm, domainToTrack } =
    useContext(UtmContext);

  const getButtonClick = (actualDomain) => {
    const domainName = actualDomain.split(".");
    if (domainName[0] !== domainToTrack) {
      let tldId = 0;
      const pageRef = window.location.origin;
      const availability = "a";
      const searchTrack = async () => {
        let trackResponse = await getTrackSearchAPI(
          domainName[0],
          tldId,
          pageRef,
          utmSource,
          utmCampaign,
          utmMedium,
          utmTerm,
          availability
        );
      };
      searchTrack();
    }
    navigate("/available", { state: { domain: actualDomain } });
  };

  return (
    <button
      type="submit"
      className="btn btn-sm btn-primary btn-outline"
      onClick={() => getButtonClick(actualDomain)}
    >
      <span>
        Get It
      </span>
    </button>
  );
}

export default GetIt;
