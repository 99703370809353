import React from "react";
import SuggestedDomainList from "./SuggestedDomainList";

function TldList({ suggestedDomain }) {
  return (
    <div className="section-row pt-0 mb-3 mb-md-0">
      <div className="container">
        <div className="small-container">
          <div className="tld-tabs">
            <ul className="nav nav-tabs" id="tldTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="SuggestedTld-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#SuggestedTld"
                  type="button"
                  role="tab"
                  aria-controls="SuggestedTld"
                  aria-selected="true"
                >
                  Suggested Names
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <SuggestedDomainList suggestedDomain={suggestedDomain} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TldList;
