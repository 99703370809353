import React from "react";

function FacebookLogo(props) {
  return (
    <svg
      width="9"
      height="18"
      viewBox="0 0 9 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.25 6H0V9H2.25V18H6V9H8.7315L9 6H6V4.74975C6 4.0335 6.144 3.75 6.83625 3.75H9V0H6.144C3.447 0 2.25 1.18725 2.25 3.46125V6Z"
        fill="white"
      />
    </svg>
  );
}

export default FacebookLogo;
