import React from "react";

function EmailLogo(props) {
  return (
    <svg
      width="20"
      height="15"
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 8.09417L0.0125 0H19.9875L10 8.09417ZM10 10.2392L0 2.135V15H20V2.135L10 10.2392Z"
        fill="white"
      />
    </svg>
  );
}

export default EmailLogo;
