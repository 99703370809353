import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import featuredStoriesList from "../../Constants/Stories/featuredStoriesList";
function featuredstoriesSplide(props) {
  return (
    <Splide
      className="stories-slider"
      options={{
        rewind: true,
        type: "loop",
        perPage: 1,
        pagination: "false",
      }}
    >
      {featuredStoriesList.map((story, index) => {
        return (
          <SplideSlide key={index}>
            <div className="stories-mian-box">
              <div className="stories-img mb-3">
                <div className="top-line-box">
                  <span className="line-left"></span>
                  <span className="line-right"></span>
                </div>
                <a href={story.link} aria-label="Story Image">
                  <img
                    src={`${process.env.REACT_APP_TLD_IMAGE_URL}images/${story.image}`}
                    alt={story.Name}
                  />
                </a>
              </div>
              <div className="stories-box">
                <h2>{story.Name}</h2>
                <p>{story.Description}</p>
                <div className="stories-btn-box d-flex align-items-center">
                  <a
                    href={story.link}
                    aria-label="Story Link"
                    className="btn btn-primary"
                  >
                    Learn more{" "}
                    <span className="no-show-label">{story.Name}</span>
                  </a>
                  <a
                    href="/whosontv"
                    aria-label="See who else"
                    className="text-uppercase"
                  >
                    See who else is on .tv
                  </a>
                </div>
              </div>
            </div>
          </SplideSlide>
        );
      })}
    </Splide>
  );
}

export default featuredstoriesSplide;
