import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import CopylinkLogo from "../Logos/CopylinkLogo";
import EmailLogo from "../Logos/EmailLogo";
import FacebookLogo from "../Logos/FacebookLogo";
import LinkedinLogo from "../Logos/LinkedinLogo";
import TwitterLogo from "../Logos/TwitterLogo";
function ShareInterview({ interviewUrl }) {
  const [linkedCopied, setLinkedCopied] = useState("copy-text");

  const copyLink = () => {
    setLinkedCopied("copy-text copied");
    setTimeout(() => {
      setLinkedCopied("copy-text");
    }, 3000);
  };

  return (
    <div className="interview-share-link container mt-0 pb-md-4">
      <FacebookShareButton url={interviewUrl}>
        <FacebookLogo />
      </FacebookShareButton>
      <TwitterShareButton url={interviewUrl}>
        <TwitterLogo />
      </TwitterShareButton>
      <EmailShareButton url={interviewUrl} subject="TurnOn.tv">
        <EmailLogo />
      </EmailShareButton>
      <LinkedinShareButton url={interviewUrl}>
        <LinkedinLogo />
      </LinkedinShareButton>

      <CopyToClipboard text={interviewUrl}>
        <button
          onClick={() => {
            copyLink();
          }}
          className="bg-transparent"
        >
          <CopylinkLogo />
        </button>
      </CopyToClipboard>
      <span className={linkedCopied}>link copied</span>
    </div>
  );
}

export default ShareInterview;
