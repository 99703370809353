import React, { useState, useRef } from "react";
import VolumeupdownAnimation from "../VolumeupdownAnimation";
import ReCAPTCHA from "react-google-recaptcha";
import { sendContactForm } from "../../Services/contactusapi";
import { Zoom } from "react-awesome-reveal";

function AccrditedRegistrarResellerForm() {
  const [successMessage, setSuccessMessage] = useState(false);
  const [companynameError, setcompanynameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [icannError, setIcannError] = useState(false);
  const [captchaError, setCaptchaError] = useState(false);

  const recaptchaRef = useRef(null);
  const companynameRef = useRef(null);
  const emailRef = useRef(null);
  const icannRef = useRef(null);

  const [companyname, setCompanyname] = useState("");
  const [email, setEmail] = useState("");
  const [icann, setIcann] = useState("");

  const handleCompanyName = (e) => {
    e.preventDefault();
    setCompanyname(e.target.value);
  };

  const handleEmail = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const handleIcann = (e) => {
    e.preventDefault();
    setIcann(e.target.value);
  };

  const onsubmitHandler = (e) => {
    e.preventDefault();
    const recaptchaValue = recaptchaRef.current.getValue();

    if (email === "" || email.trim() === "") {
      setEmailError(true);
      setSuccessMessage(false);
    } else {
      setEmailError(false);
    }

    if (companyname === "" || companyname.trim() === "") {
      setcompanynameError(true);
      setSuccessMessage(false);
    } else {
      setcompanynameError(false);
    }

    if (icann === "" || icann.trim() === "") {
      setIcannError(true);
      setSuccessMessage(false);
    } else {
      setIcannError(false);
    }

    if (recaptchaValue === "") {
      setCaptchaError(true);
      setSuccessMessage(false);
    } else {
      setCaptchaError(false);
    }

    if (
      email !== "" &&
      companyname !== "" &&
      icann !== "1" &&
      recaptchaValue !== "" &&
      email.trim() !== "" &&
      companyname.trim() !== ""
    ) {
      /*bodydata*/
      let bodyFormData = new FormData();
      bodyFormData.append("site_id", "NDU=");
      bodyFormData.append("email", email);
      bodyFormData.append("companyname", companyname);
      bodyFormData.append("icann", icann);

      sendContactForm(bodyFormData, function (response) {
        if (response.status === 1) {
          //setEmailError(false);
          setSuccessMessage(true);
          setCompanyname("");
          setEmail("");
          setIcann("");
          companynameRef.current.value = "";
          emailRef.current.value = "";
          icannRef.current.value = "";
          recaptchaRef.current?.reset();
        } else {
          setSuccessMessage(false);
          //setEmailError(true);
        }
      });
    }
  };

  return (
    <>
      <div id="Reseller">
        <Zoom>
          <div className="section-row">
            <div className="container">
              <div className="mt-0 mb-0 mb-md-5">
                <VolumeupdownAnimation />
              </div>
              <div className="section-title text-center mb-2 mb-sm-3  mb-md-5 mt-4 mt-md-0">
                <h2>Request to become a .TV Registrar Partner</h2>
              </div>
              <div className="row justify-content-center">
                <div className="col-md-12 mb-4 mb-md-0">
                  <div className="contact-from-box">
                    {successMessage === true ? (
                      <div
                        className="alert alert-success mb-3"
                        id="UploadSuccess"
                      >
                        Thank you for your request! We will get in touch with you
                        shortly.
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="contact-from">
                      <form
                        action="#"
                        onSubmit={(e) => {
                          onsubmitHandler(e);
                        }}
                      >
                        <div className="row">
                          <div className="col-md-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className={
                                companyname
                                  ? "form-control filled"
                                  : "form-control unfilled"
                              }
                              name="companyname"
                              onChange={(e) => {
                                handleCompanyName(e);
                              }}
                              ref={companynameRef}
                            />
                            <label>
                              Company Name <span className="required">*</span>
                            </label>
                            {companynameError === true ? (
                              <small className="text-danger">
                                Please enter company name
                              </small>
                            ) : (
                              ""
                            )}
                          </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                type="text"
                                className={
                                  icann
                                    ? "form-control filled"
                                    : "form-control unfilled"
                                }
                                name="icann"
                                onChange={(e) => {
                                  handleIcann(e);
                                }}
                                ref={icannRef}
                              />
                              <label>
                                ICANN ID (GURID) <span className="required">*</span>
                              </label>
                              {icannError === true ? (
                                <small className="text-danger">
                                  Please enter ICANN ID (GURID)
                                </small>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <input
                                type="text"
                                className={
                                  email
                                    ? "form-control filled"
                                    : "form-control unfilled"
                                }
                                name="email"
                                onChange={(e) => {
                                  handleEmail(e);
                                }}
                                ref={emailRef}
                              />
                              <label>
                                Email Address <span className="required">*</span>
                              </label>
                              {emailError === true ? (
                                <small className="text-danger">
                                  Please enter valid email
                                </small>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <div className="recaptcha-box">
                                <ReCAPTCHA
                                  sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
                                  ref={recaptchaRef}
                                />
                              </div>
                              {captchaError === true ? (
                                <small className="text-danger">
                                  Please verify that you are not a robot
                                </small>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 text-md-end">
                            <div className="form-group">
                              <button
                                type="submit"
                                className="btn btn-sm btn-primary"
                              >
                                <span>Submit</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Zoom>
      </div>
    </>
  );
}

export default AccrditedRegistrarResellerForm;
