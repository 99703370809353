import React from "react";
import { Fade } from "react-awesome-reveal";

function AvailableProgressBar({ currentstep }) {
  return (
    <>
      <div className="container">
        <div className="small-container">
          <div className="row">
            <div className="col-md-4">
              <Fade damping={0.1}>
                <div
                  className={
                    currentstep === "ALL"
                      ? `domain-process-step step-search`
                      : currentstep === "search"
                      ? `domain-process-step step-search`
                      : `domain-process-step step-search step-disable`
                  }
                >
                  <span>01</span>
                  <h2>SEARCH</h2>
                  <p>
                    Search for your preferred .TV domain name and check
                    availability.
                  </p>
                </div>
              </Fade>
            </div>
            <div className="col-md-4">
              <Fade damping={0.5}>
                <div
                  className={
                    currentstep === "ALL"
                      ? `domain-process-step step-select`
                      : currentstep === "select"
                      ? `domain-process-step step-select`
                      : `domain-process-step step-select step-disable`
                  }
                >
                  <span>02</span>
                  <h2>SELECT</h2>
                  <p>
                    Choose a trusted retailer. We've partnered with the best
                    worldwide!
                  </p>
                </div>
              </Fade>
            </div>
            <div className="col-md-4">
              <Fade damping={1}>
                <div
                  className={
                    currentstep === "ALL"
                      ? `domain-process-step step-checkout`
                      : currentstep === "checkout"
                      ? `domain-process-step step-checkout`
                      : `domain-process-step step-checkout step-disable`
                  }
                >
                  <span>03</span>
                  <h2>CHECKOUT</h2>
                  <p>
                    Complete the purchase with your selected retail partner.
                  </p>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AvailableProgressBar;
