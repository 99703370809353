import React from "react";
import VolumeupdownAnimation from "../VolumeupdownAnimation";

function AboutBanner() {
  return (
    <>
      <div className="page-title-box">
        <div className="container">
          <div className="page-title px-4 px-md-0">
            <h1>ABOUT US</h1>
            <p>
              The .TV top-level domain is made for today’s democratized world of
              content creation, streaming, and social media. Whether launching a
              new film or music video, or streaming your day-to-day content, .TV
              tells the world you’ve got something worth watching. Bloggers,
              YouTubers, Twitch Streamers, Instagram Influencers, TikTok Stars,
              and everyday entrepreneurs, are growing their fans, followers, and
              impact on .TV. And you can too. You can register your .TV domain
              name through any of our trusted retail partners.{" "}
              <a
                href="/accreditedregistrars"
                aria-label="Accredited Registrars"
              >
                click here
              </a>
            </p>
          </div>
        </div>
      </div>

      <div className="mt-3 mt-md-5 mb-4 mb-md-5">
        <VolumeupdownAnimation />
      </div>
    </>
  );
}

export default AboutBanner;
