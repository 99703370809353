import React from "react";
import VolumeupdownAnimation from "../VolumeupdownAnimation";

function TvHomeWhosontv(props) {
  return (
    <div className="section-row">
      <div className="container">
        <div className="mt-3 mt-md-4 mb-4 mb-md-5">
          <VolumeupdownAnimation />
        </div>
        <div className="whos-text text-center">
          <span>Who’s on .TV</span>
        </div>
        <div className="whos-text-details">
          <h2>
            NOT EVERYONE GETS TO BE ON TV
            <span className="whos-video">
              <video
                preload="auto"
                width="100%"
                height="100%"
                playsInline={true}
                autoPlay={true}
                muted={true}
                loop={true}
                poster={`${process.env.REACT_APP_TLD_IMAGE_URL}images/yellow-tv.jpg`}
                src={`${process.env.REACT_APP_TLD_IMAGE_URL}videos/p-inline-color.mp4`}
              ></video>
            </span>
            BUT A .TV DOMAIN
          </h2>
          <h2> LETS ANYONE SHOW OFF THEIR LATEST </h2>
          <h2>
            <span className="whos-video">
              <video
                preload="auto"
                width="100%"
                height="100%"
                playsInline={true}
                autoPlay={true}
                muted={true}
                loop={true}
                poster={`${process.env.REACT_APP_TLD_IMAGE_URL}images/yellow-tv.jpg`}
                src={`${process.env.REACT_APP_TLD_IMAGE_URL}videos/p-inline-1.mp4`}
              ></video>
            </span>
            moves, gameplay, or
            <span className="whos-video">
              <video
                preload="auto"
                width="100%"
                height="100%"
                playsInline={true}
                autoPlay={true}
                muted={true}
                loop={true}
                poster={`${process.env.REACT_APP_TLD_IMAGE_URL}images/yellow-tv.jpg`}
                src={`${process.env.REACT_APP_TLD_IMAGE_URL}videos/p-inline-2-2.mp4`}
              ></video>
            </span>
            vocals.
          </h2>
          <h2>Check out these creators building their</h2>
          <h2>
            audience on a
            <span className="whos-video">
              <video
                preload="auto"
                width="100%"
                height="100%"
                playsInline={true}
                autoPlay={true}
                muted={true}
                loop={true}
                poster={`${process.env.REACT_APP_TLD_IMAGE_URL}images/yellow-tv.jpg`}
                src={`${process.env.REACT_APP_TLD_IMAGE_URL}videos/dot-TV-shift.mp4`}
              ></video>
            </span>
            .tv domain name.
          </h2>
        </div>
      </div>
    </div>
  );
}

export default TvHomeWhosontv;
