import React, { useEffect, useState } from "react";
import GetIt from "../../Buttons/GetIt";

function SuggestedDomainList({ suggestedDomain }) {
  return (
    <div
      className="tab-pane fade show active"
      id="SuggestedTld"
      role="tabpanel"
      aria-labelledby="SuggestedTld-tab"
    >
      <div className="tld-tabs-list">
        {suggestedDomain.map((domain, index) => {
          return (
            <div className="tld-tabs-box" key={index}>
              <div className="tld-tabs-title-box d-sm-flex align-items-center">
                <div className="tld-name-tabs-box d-sm-flex align-items-center flex-row-reverse me-auto">
                  <h4 className="my-2 me-sm-3">
                    {domain.DomainName.split(".")[0]}
                    <strong>.{domain.DomainName.split(".")[1]}</strong>
                  </h4>
                </div>
                <GetIt actualDomain={domain.DomainName} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default SuggestedDomainList;
