import React from "react";
import TvLogo from "../Logos/TvLogo";
import VolumeupdownAnimation from "../VolumeupdownAnimation";
function InterviewTitle(props) {
  return (
    <div className="page-title-box">
      <div className="container">
        <div className="page-title">
          {/* <figure>
            <TvLogo />
          </figure> */}
          <h1>INTERVIEWS</h1>
          <div className="mt-5 mb-4 mb-md-2">
            <VolumeupdownAnimation />
          </div>
        </div>
      </div>
    </div>
  );
}

export default InterviewTitle;
