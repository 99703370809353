import React from "react";
import TvLogo from "../Logos/TvLogo";
import VolumeupdownAnimation from "../VolumeupdownAnimation";

function StoriesPagetitle(props) {
  return (
    <div className="page-title-box">
      <div className="container">
        <div className="page-title">
          <h1>Stories</h1>
          <p>
            Content creators around the world are finding ways to tap into their
            audience and get seen. And there’s no better domain than .TV for
            letting people know you’ve got something worth watching.{" "}
          </p>
        </div>
      </div>
    </div>
  );
}

export default StoriesPagetitle;
