const ShowcaseList = [
  {
    Name: "twitch.tv",
    image: "showcasewebsites/thumbnail_Twitch.png",
    link: "",
  },
  {
    Name: "arte.tv",
    image: "showcasewebsites/thumbnail_Arte.png",
    link: "",
  },
  {
    Name: "qwest.tv",
    image: "showcasewebsites/thumbnail_Qwest.png",
    link: "",
  },
  {
    Name: "mezzo.tv",
    image: "showcasewebsites/thumbnail_mezzo.png",
    link: "",
  },
  {
    Name: "smartclip.tv",
    image: "showcasewebsites/thumbnail_Smartclip.png",
    link: "",
  },
  {
    Name: "viewmedia.tv",
    image: "showcasewebsites/thumbnail_Viewmedia.png",
    link: "",
  },
  {
    Name: "theaibs.tv",
    image: "showcasewebsites/thumbnail_theaibs.png",
    link: "",
  },
  {
    Name: "arabicagroup.tv",
    image: "showcasewebsites/thumbnail_arabicagroup.png",
    link: "",
  },
  {
    Name: "oneafrica.tv",
    image: "showcasewebsites/thumbnail_oneafrica.png",
    link: "",
  },
  {
    Name: "fashionafrica.tv",
    image: "showcasewebsites/thumbnail_Fashionafrica.png",
    link: "",
  },
  {
    Name: "tmgstudios.tv",
    image: "showcasewebsites/thumbnail_tmgstudios.png",
    link: "",
  },
  {
    Name: "yunxi.tv",
    image: "showcasewebsites/thumbnail_yunxi.png",
    link: "",
  },
  {
    Name: "ninjalifehacks.tv",
    image: "showcasewebsites/thumbnail_nijalifehacks.png",
    link: "",
  },
  {
    Name: "upf.tv",
    image: "showcasewebsites/thumbnail_upf.png",
    link: "",
  },
  {
    Name: "piehole.tv",
    image: "showcasewebsites/thumbnail_piehole.png",
    link: "",
  },
  {
    Name: "society.tv",
    image: "showcasewebsites/thumbnail_society.png",
    link: "",
  },
  {
    Name: "cookingwithgranny.tv",
    image: "showcasewebsites/thumbnail_cookingwithgranny.png",
    link: "",
  },
  {
    Name: "notanothercookingshow.tv",
    image: "showcasewebsites/thumbnail_notanothercookingshow.png",
    link: "",
  },
  {
    Name: "dancetutorials.tv",
    image: "showcasewebsites/thumbnail_dancetutorials.png",
    link: "",
  },
  {
    Name: "tmilly.tv",
    image: "showcasewebsites/thumbnail_tmilly.png",
    link: "",
  },
  {
    Name: "medici.tv",
    image: "showcasewebsites/thumbnail_medici.png",
    link: "",
  },
  {
    Name: "numeridanse.tv",
    image: "showcasewebsites/thumbnail_numeridanse.png",
    link: "",
  },
  {
    Name: "thecoconet.tv",
    image: "showcasewebsites/thumbnail_thecoconet.png",
    link: "",
  },
  {
    Name: "thetradeoff.tv",
    image: "showcasewebsites/thumbnail_thetradeoff.png",
    link: "",
  },
  {
    Name: "fyi.tv",
    image: "showcasewebsites/thumbnail_fyi.png",
    link: "",
  },
  {
    Name: "mrandmrsdiy.tv",
    image: "showcasewebsites/thumbnail_mrandmsdiy.png",
    link: "",
  },
  {
    Name: "tdn.tv",
    image: "showcasewebsites/thumbnail_tdn.png",
    link: "",
  },
  {
    Name: "pluto.tv",
    image: "showcasewebsites/thumbnail_pluto.png",
    link: "",
  },
  {
    Name: "ilbeauty.tv",
    image: "showcasewebsites/thumbnail_ilbeauty.png",
    link: "",
  },
  {
    Name: "theartofphotography.tv",
    image: "showcasewebsites/thumbnail_theartofphotography.png",
    link: "",
  },
  {
    Name: "twit.tv",
    image: "showcasewebsites/thumbnail_twit.png",
    link: "",
  },
  {
    Name: "brid.tv",
    image: "showcasewebsites/thumbnail_brid.png",
    link: "",
  },
  {
    Name: "bilibili.tv",
    image: "showcasewebsites/thumbnail_Bilibili.png",
    link: "",
  },
  {
    Name: "retrocrush.tv",
    image: "showcasewebsites/thumbnail_retrocrush.png",
    link: "",
  },
  {
    Name: "flosports.tv",
    image: "showcasewebsites/thumbnail_Flosports.png",
    link: "",
  },
  {
    Name: "theswitch.tv",
    image: "showcasewebsites/thumbnail_theswitch.png",
    link: "",
  },
  {
    Name: "jasonmiller.tv",
    image: "showcasewebsites/thumbnail_Jasonmiller.png",
    link: "",
  },
  {
    Name: "maff.tv",
    image: "showcasewebsites/thumbnail_Maff.png",
    link: "",
  },
  {
    Name: "richardkline.tv",
    image: "showcasewebsites/thumbnail_Richardkline.png",
    link: "",
  },
  {
    Name: "petegeorge.tv",
    image: "showcasewebsites/thumbnail_Petegeorge.png",
    link: "",
  },
  {
    Name: "jennyyang.tv",
    image: "showcasewebsites/thumbnail_Jennyyang.png",
    link: "",
  },
  {
    Name: "dancummins.tv",
    image: "showcasewebsites/thumbnail_Dancummins.png",
    link: "",
  },
  {
    Name: "inel.tv",
    image: "showcasewebsites/thumbnail_Inel.png",
    link: "",
  },
  {
    Name: "clintarthur.tv",
    image: "showcasewebsites/thumbnail_Clintarthur.png",
    link: "",
  },
  {
    Name: "rickjordan.tv",
    image: "showcasewebsites/thumbnail_Rickjordan.png",
    link: "",
  },
  {
    Name: "kevinharrington.tv",
    image: "showcasewebsites/thumbnail_Kevinharrington.png",
    link: "",
  },
  {
    Name: "spencerlodge.tv",
    image: "showcasewebsites/thumbnail_Spencerlodge.png",
    link: "",
  },
  {
    Name: "heusser.tv",
    image: "showcasewebsites/thumbnail_Heusser.png",
    link: "",
  },
];
export default ShowcaseList;
