import React from "react";
import faqInfo from "../../Constants/faq/config.json";
import VolumeupdownAnimation from "../VolumeupdownAnimation";
import { Reveal, Fade, Roll, Zoom } from "react-awesome-reveal";

function FaqQuestionAnswers() {
  return (
    <>
      {faqInfo["faq"].map((posFields, index) => {
        //console.log(posFields["title"]);
        return (
          <Fade>
            <div className="section-row pt-0 mb-3 mb-md-0" key={index}>
              <div className="container">
                <div className="faq-accordion px-3 px-md-0">
                  <div className="row justify-content-center">
                    <div className="ccol-xl-8 col-lg-10">
                      <div className="accordion mt-3 mt-md-0">
                        {posFields["posts"].map((postdata, i) => {
                          //console.log(postdata);
                          //console.log(postdata["question"]);
                          return (
                            <div
                              className="accordion-item"
                              id={"faqAccordion" + index + i}
                              key={i}
                            >
                              <div
                                className="accordion-header"
                                id="flush-headingOne"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={
                                    "#Registry-DomainsOne" + index + i
                                  }
                                  aria-expanded="false"
                                  aria-controls="Registry-DomainsOne"
                                >
                                  <span>{postdata["question"]}</span>
                                </button>
                              </div>
                              <div
                                id={"Registry-DomainsOne" + index + i}
                                className="accordion-collapse collapse"
                                aria-labelledby="flush-headingOne"
                                data-bs-parent={"#faqAccordion" + index + i}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: postdata["answer"],
                                  }}
                                  className="accordion-body font-l"
                                ></div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        );
      })}
      <Zoom>
        <div className="section-row mb-4 mb-md-5 d-md-block">
          <div className="container">
            <div className="section-title text-center mb-4 mb-md-5 pb-md-3">
              <div className="mb-4">
                <VolumeupdownAnimation />
              </div>
              <h2 className="mb-3">QUESTIONS?</h2>
              <p className="mb-4 mb-md-5">Reach out for more information.</p>
              <a aria-label='Contact us' href="/contact" className="btn btn-primary">
                Contact us
              </a>
            </div>
          </div>
        </div>
      </Zoom>
    </>
  );
}

export default FaqQuestionAnswers;
