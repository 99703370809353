import React from "react";

function CopylinkLogo(props) {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5278 1.47315C17.4907 3.43664 17.4907 6.622 15.5278 8.58619L12.8077 11.3055L11.3053 9.8024L14.0247 7.08241C15.1574 5.9505 15.1574 4.10813 14.024 2.97622C12.892 1.8436 11.0495 1.8436 9.91757 2.97552L7.19745 5.69479L5.695 4.19243L8.41513 1.47315C10.378 -0.491049 13.5628 -0.491049 15.5278 1.47315ZM9.80282 11.3041L7.08269 14.0233C5.95072 15.1567 4.10826 15.156 2.97487 14.0233C1.8429 12.8914 1.8429 11.0483 2.97487 9.91715L5.695 7.19716L4.19256 5.69479L1.47243 8.41478C-0.490455 10.3783 -0.491164 13.5629 1.47243 15.5271C3.43673 17.4906 6.62225 17.4913 8.58514 15.5271L11.3053 12.8071L9.80282 11.3041Z"
        fill="#FFF7F7"
      />
    </svg>
  );
}

export default CopylinkLogo;
