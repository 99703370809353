import axios from "axios";


export const sendContactForm = (data,callback) => {  
    axios({
        method: 'POST',
        headers: { 'Content-Type': 'Multipart/form-data'},
        url: `https://www.expedrion.biz/cms/subscriber_api/subscribe/index.php`,
        data: data
      }).then(response =>{
        callback(response.data);
      }).catch( err =>{
        console.log(err);
      });
    
};




  