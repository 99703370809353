import React from "react";

function SearchLogo(props) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0095 5.5084L18.8649 6.65295C20.6282 8.42033 21.5059 10.7175 21.5059 13.0349C21.5059 15.3483 20.6282 17.6496 18.8649 19.4169C17.0975 21.1803 14.8003 22.0579 12.4829 22.0579C10.1696 22.0579 7.86831 21.1803 6.10093 19.4169C4.33759 17.6496 3.45995 15.3524 3.45995 13.0349C3.45995 10.7216 4.33759 8.42033 6.10093 6.65295C7.86831 4.88961 10.1655 4.01199 12.4829 4.01199C14.7963 4.01199 17.0975 4.88961 18.8649 6.65295L20.0095 5.5084L21.154 4.36385C18.7638 1.97363 15.6173 0.772461 12.487 0.772461C9.35665 0.772461 6.21012 1.97363 3.8199 4.36385C1.42969 6.75406 0.228516 9.90057 0.228516 13.0309C0.228516 16.1612 1.42969 19.3077 3.8199 21.698C6.21012 24.0882 9.35665 25.2893 12.487 25.2893C15.6173 25.2893 18.7638 24.0882 21.154 21.698C23.5442 19.3077 24.7454 16.1612 24.7454 13.0309C24.7454 9.90057 23.5442 6.75406 21.154 4.36385L20.0095 5.5084Z"
        fill="#5CFFFA"
      />
      <path
        d="M18.8648 21.6978L28.4904 31.3234C29.1213 31.9543 30.1445 31.9543 30.7795 31.3234C31.4104 30.6925 31.4104 29.6693 30.7795 29.0343L21.1539 19.4087C20.523 18.7778 19.4998 18.7778 18.8648 19.4087C18.2339 20.0437 18.2339 21.0669 18.8648 21.6978Z"
        fill="#5CFFFA"
      />
    </svg>
  );
}

export default SearchLogo;
