import React from "react";
import TvLogo from "../Logos/TvLogo";
import headerItems from "../../Constants/headerItems";
import MenuAnimation from "../common/MenuAnimation";
function TvHeader(props) {
  return (
    <header id="header" className="px-1 px-md-3">
      <div className="container-fluid">
        <div className="d-flex align-items-center">
          <div
            className="logo"
            data-aos="fade-down"
            data-aos-delay="2000"
            data-aos-duration="1000"
          >
            <a href="/" aria-label="TurnOn.tv">
              <figure>
                <TvLogo />
              </figure>
            </a>
          </div>
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#headerMenu"
            aria-controls="headerMenu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="menu-lines"></span>
          </button>
          <div
            className="header-menu"
            data-aos="fade-down"
            data-aos-delay="2000"
            data-aos-duration="1000"
          >
            <div className="collapse navbar-collapse" id="headerMenu">
             
              <MenuAnimation/>
              
            </div>
            
          </div>
        </div>
      </div>
    </header>
  );
}

export default TvHeader;
