import React from "react";
import StoryMarque from "../common/MarqueAnimation";

function NextInterviewScroll(props) {
  return (
    <div className="highlight-text">
      <div className="top-line-box">
        <span className="line-left"></span>
        <span className="line-right"></span>
      </div>
      <h2>
        <StoryMarque
          velocity={"-1"}
          title={[
            <span>
              {props.nextInterviews.map((interview, index) => {
                return (
                  <span key={index}>
                    GO TO THE NEXT STORY
                    <a aria-label="Interview Link" href={interview.link}>
                      <span className="highlight-video">
                        {" "}
                        <h6>
                          Next up <br />
                          {interview.name}
                        </h6>{" "}
                        <img
                          src={`${process.env.REACT_APP_TLD_IMAGE_URL}${interview.image}`}
                          alt="highlight image"
                        ></img>
                      </span>{" "}
                    </a>
                  </span>
                );
              })}
            </span>,
          ]}
        />
      </h2>
    </div>
  );
}

export default NextInterviewScroll;
