import React from "react";
import { ScrollParallax } from "react-just-parallax";

function TvHomeVideopiller(props) {
  return (
    <div className="section-row">
      <div className="screen-video-box">
        <div className="video-piller">
          <ScrollParallax className="video-piller-box" strength="-0.4">
            <span></span>
          </ScrollParallax>
          <ScrollParallax className="video-piller-box" strength="-0.3">
            <span></span>
          </ScrollParallax>
          <ScrollParallax className="video-piller-box" strength="-0.2">
            <span></span>
          </ScrollParallax>
          <ScrollParallax className="video-piller-box" strength="-0.1">
            <span></span>
          </ScrollParallax>
        </div>
        <div className="container">
          <div className="screen-video">
            <video
              preload="auto"
              playsInline={true}
              autoPlay={true}
              muted={true}
              loop={true}
              poster={`${process.env.REACT_APP_TLD_IMAGE_URL}images/yellow-tv.jpg`}
              src={`${process.env.REACT_APP_TLD_IMAGE_URL}videos/dot-tv main.mp4`}
            ></video>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TvHomeVideopiller;
