import React from "react";

function TvLoaderAnimation(props) {
  return (
    <>
      <div className="star-load">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 1920 1080"
          style={{ enableBackground: "new 0 0 1920 1080" }}
          xmlSpace="preserve"
        >
          <g>
            <path
              className="st0"
              d="M1829.7,508.8c-21.4-3.4-351.7-4.7-351.7-4.7c-292.7,3-403.3-105.6-403.3-105.6
              c-101.6-69.4-105.3-267.7-105.3-267.7c-3.8-29.7-6.9-40.1-9.3-40.6v0c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0v0c-1.4,0.3-3.1,4-5,13.1
              c-3,14.1-3.9,28.6-3.1,43c2.7,46.9,1.6,188.1-99.3,256.9c0,0-117.9,103.8-410.6,100.8c0,0-330.2,1.3-351.7,4.7c0,0-203.1,30.2,0,63
              c0,0,63,13.4,298.4,9.4c0,0,310.8-16.8,466.6,100.9c0,0,96.5,57.3,89.8,213.5c0,0-1,68.7,14.1,94.2l0.2-0.1h-0.1
              c15.1-25.5,15.5-94.2,15.5-94.2c-6.7-156.2,89.8-213.5,89.8-213.5c155.9-117.7,466.6-100.9,466.6-100.9
              c235.3,4,298.4-9.4,298.4-9.4C2032.9,538.9,1829.7,508.8,1829.7,508.8z"
            />
          </g>
        </svg>
      </div>
      <div className="row g-0 loader-piller">
        <div className="col piller-01">
          <span style={{ backgroundColor: "#f6ff03" }}></span>
        </div>
        <div className="col piller-02">
          <span style={{ backgroundColor: "#5dfffa" }}></span>
        </div>
        <div className="col piller-03">
          <span style={{ backgroundColor: "#04f500" }}></span>
        </div>
        <div className="col piller-04">
          <span style={{ backgroundColor: "#ff00b8" }}></span>
        </div>
      </div>
    </>
  );
}

export default TvLoaderAnimation;
