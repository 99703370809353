import React from "react";
import fmenu from "../../Constants/fmenu";
import GdpoweredbyLogo from "../Logos/GdpoweredbyLogo";
import InstagramLogo from "../Logos/InstagramLogo";
import TvLogo from "../Logos/TvLogo";
import YoutubeLogo from "../Logos/YoutubeLogo";
import { useNavigate } from "react-router-dom";

function FooterTop(props) {
  const navigate = useNavigate();
  const handleClick = (e, link) => {
    if (link === "") {
      e.preventDefault();
    }
  };
  return (
    <div className="footer-top">
      <div className="d-md-flex align-items-center">
        <div className="d-flex footer-logo-box">
          {/* <div className="flogo">
            <a href="#" aria-label="TurnOn.tv">
              <figure>
                <TvLogo />
              </figure>
            </a>
          </div> */}
          <div className="gd-powred-by-logo">
            <GdpoweredbyLogo />
          </div>
        </div>
        <div className="d-flex fsocial-box">
          <InstagramLogo />
          <YoutubeLogo />
        </div>
      </div>
      <div className="fmenu">
        <ul>
          {fmenu.map((item, index) => {
            return (
              <li key={index}>
                <a
                  href={item[1]}
                  onClick={(e) => {
                    handleClick(e, item[1]);
                  }}
                  target={`${item[2]}`}
                >
                  {item[0]}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default FooterTop;
