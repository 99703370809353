import React, { useEffect, useState, useRef } from "react";
import { Navigate, useLocation } from "react-router-dom";
import TvFooter from "../TvFooter/TvFooter";
import TvHeader from "../TvHeader/TvHeader";
import AvailableProgressBar from "./AvailableProgressBar";
import AvailableDomainStatus from "./AvailableDomainStatus";
import Registrar from "./Registrar";
import SearchBox from "../Search/SearchBox";
import FooterScroll from "../FooterScroll";
import FooterSearchbar from "../FooterSearchbar";
import { useIsInViewport } from "../../Services/tvAPI";
import { Helmet } from "react-helmet";

function Available() {
  const [tldId, setTldId] = useState(6);
  const location = useLocation();
  let domain = "";
  let domain_without_tld = "";
  if (location.state !== null) {
    domain = location.state.domain;
    const arr = domain.split(".");
    domain_without_tld = arr[0];
  }

  if (domain === "" || domain === null) {
    document.location.href = "/";
  }

  useEffect(() => {
    window.dataLayer.push({
      event: "Select domain click",
      page: {
        url: window.location.href,
        title: "TurnOn.tv",
        tld: ".tv",
      },
    });
  }, []);
  const ref2 = useRef(null);

  const isInViewport2 = useIsInViewport(ref2);

  const [footerSearchon, setFooterSearchon] = useState("headersearchshow");

  useEffect(() => {
    if (isInViewport2 === true) {
      setFooterSearchon("headersearchhide");
    } else {
      setFooterSearchon("headersearchshow");
    }
  }, [isInViewport2]);

  return (
    <>
      <Helmet>
        <title>Confirm .TV Availability &#38; Select Trusted Retail Partners &#124; .TV Domain</title> 
        <meta name="website" content="http://turnon.tv/available"/>
        <meta name="description" content="Choose a trusted retail partner to complete your .TV domain registration. We’ll redirect you to their site to complete your purchase."/>
      </Helmet>
      <TvHeader />
      <div className={footerSearchon + ` home-page-search`}>
        <SearchBox domain={domain_without_tld} />
      </div>
      <AvailableProgressBar currentstep={"checkout"} />
      {domain ? <AvailableDomainStatus domain={domain} /> : ""}
      {tldId ? <Registrar tld_id={tldId} domain={domain} /> : ""}
      <FooterScroll />
      <div ref={ref2}>
        <FooterSearchbar url={window.location.pathname} />
      </div>
      <TvFooter />
    </>
  );
}

export default Available;
