import React, { useEffect, useState, useRef } from "react";
import TvFooter from "../TvFooter/TvFooter";
import TvHeader from "../TvHeader/TvHeader";
import HeaderSearch from "../HeaderSearch";
import InterviewMarqueeImage from "./InterviewMarqueeImage";
import { useParams } from "react-router-dom";
import NextInterviewScroll from "./NextInterviewScroll";
import availableInterviews from "../../Constants/Interview/availableInterviews";
import ShareInterview from "./ShareInterview";
import FooterScroll from "../FooterScroll";
import FooterSearchbar from "../FooterSearchbar";
import { useIsInViewport } from "../../Services/tvAPI";
import { Helmet } from "react-helmet";

function Interview(props) {
  const urlParams = useParams();
  const interview_name = urlParams.interview_name;
  const [availableinterview, setAvailableinterview] = useState("");
  const [availabletested, setAvailabletested] = useState("");
  const [interviewInfo, setInterviewInfo] = useState({});

  if (!availableInterviews.includes(interview_name)) {
    document.location.href = "/page-not-found";
  }

  useEffect(() => {
    let t = false;
    let interviewDetail = {};
    availableInterviews.map((interview) => {
      if (interview === interview_name) {
        t = true;
        interviewDetail = require(`../../Constants/Interview/${interview_name}/config.json`);
      }
      return t;
    });
    setAvailabletested("1");
    setAvailableinterview(t);
    setInterviewInfo(interviewDetail);
  }, [interview_name]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const ref2 = useRef(null);
  const isInViewport2 = useIsInViewport(ref2);

  const [footerSearchon, setFooterSearchon] = useState("headersearchshow");

  useEffect(() => {
    if (isInViewport2 === true) {
      setFooterSearchon("headersearchhide");
    } else {
      setFooterSearchon("headersearchshow");
    }
  }, [isInViewport2]);

  let interviewContent = "";
  if (Object.keys(interviewInfo).length > 0) {
    const interviewContentData = interviewInfo.content;
    interviewContent = interviewContentData.replaceAll(
      "EXPEDRION_URL",
      process.env.REACT_APP_TLD_IMAGE_URL
    );
  }

  const usePageMeta = (imageURL, type, description, title, url) => {
    useEffect(() => {
      document
        .querySelector("meta[property='og:image']")
        .setAttribute("content", imageURL);
      document
        .querySelector("meta[property='og:type']")
        .setAttribute("content", type);
      document
        .querySelector("meta[property='og:description']")
        .setAttribute("content", description);
      document
        .querySelector("meta[property='og:title']")
        .setAttribute("content", title);
      document
        .querySelector("meta[property='og:url']")
        .setAttribute("content", url);
    }, [imageURL]);
  };

  return (
    <>
      {interviewInfo.meta_tags ? (
        <Helmet>
          <title>{interviewInfo.meta_tags.title}</title>
          <meta name="website" content={interviewInfo.meta_tags.website} />
          <meta
            name="description"
            content={interviewInfo.meta_tags.description}
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={interviewInfo.meta_tags.title} />
          <meta
            name="twitter:description"
            content={interviewInfo.meta_tags.description}
          />
          <meta
            name="twitter:image"
            content={
              process.env.REACT_APP_TLD_IMAGE_URL +
              "images" +
              interviewInfo.mobile
            }
          />
        </Helmet>
      ) : (
        ""
      )}
      {usePageMeta(
        `${process.env.REACT_APP_TLD_IMAGE_URL}images${interviewInfo.mobile}`,
        "blog",
        interviewInfo.title,
        interview_name,
        window.location.href
      )}
      <TvHeader />
      <div className={footerSearchon + ` home-page-search`}>
        <HeaderSearch url={window.location.pathname} />
      </div>
      {interviewInfo ? <InterviewMarqueeImage image={interviewInfo} /> : ""}
      {interviewContent !== "" ? (
        <div
          dangerouslySetInnerHTML={{ __html: interviewContent }}
          className="section-row post-details-box"
        ></div>
      ) : (
        ""
      )}
      <ShareInterview interviewUrl={window.location.href} />
      {interviewInfo.nextInterview ? (
        <NextInterviewScroll nextInterviews={interviewInfo.nextInterview} />
      ) : (
        ""
      )}
      <FooterScroll />
      <div ref={ref2}>
        <FooterSearchbar url={window.location.pathname} />
      </div>
      <TvFooter />
    </>
  );
}

export default Interview;
