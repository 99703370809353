import { React, useState, useRef, useEffect} from "react";
import SearchLogo from "./Logos/SearchLogo";
import { Link } from "react-router-dom";
import { validateNoCharacterDot, hasOnlySpecialCharater  } from "../Services/validation";
import { useIsInViewport } from "../Services/tvAPI";
import regex from "../Constants/regValidation";

function FooterSearchbar(props) {
  const [searchedText, setSearchedText] = useState("");

  const ref1 = useRef(null);
  const isInViewport2 = useIsInViewport(ref1);

  const completeplaceholder = "Find your .TV domain";
  const [placeholder, setPlaceholder] = useState(
    completeplaceholder.slice(0, 0)
  );
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [domainContainsALLChar, setDomainContainsALLChar] = useState(false);

  useEffect(() => {
    if (searchedText.length > 0) {
      if (hasOnlySpecialCharater(searchedText) === true) {
        setDomainContainsALLChar(true);
      } else if (searchedText.charCodeAt(0) === 46) {
        setDomainContainsALLChar(true);
      } else if (hasOnlySpecialCharater(searchedText.split(".")[0]) === true) {
        setDomainContainsALLChar(true);
      } else {
        setDomainContainsALLChar(false);
      }
    }
  }, [searchedText]);

  if (isInViewport2 === true) {
    setTimeout(() => {
      setPlaceholder(completeplaceholder.slice(0, placeholderIndex));
      if (placeholderIndex + 1 <= completeplaceholder.length) {
        setPlaceholderIndex(placeholderIndex + 1);
      }
    }, 150);
  }

  return (
    <div className="footer-search">
      <form action="#">
        <div className="d-flex align-items-center">
          <label className="no-show-label" htmlFor="fDomainSearch">Domain Name</label>
          <input
            ref={ref1}
            type="text"
            className="form-control"
            placeholder={placeholder} id="fDomainSearch"
            onKeyPress={(e) => validateNoCharacterDot(e)}
            onChange={(e) => {
              let text = e.target.value;

              if (regex.test(text)) {
                setSearchedText("");
              } else {
                setSearchedText(text);
              }
            }}
          />
          <Link
            to={
              searchedText === ""
                ? `${props.url}`
                  : domainContainsALLChar === false
                    ? `/search?domain=${searchedText}` 
                    :  `${props.url}`             
            } 
          >
            <button type="submit" className="btn btn-primary">
              <SearchLogo />
              SEARCH
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
}

export default FooterSearchbar;
