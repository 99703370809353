import React from "react";

function FooterBottom(props) {
  return (
    <div className="footer-bottom">
      <div className="d-md-flex">
        <div className="footer-bottom-left order-md-2">
          <div className="footer-bottom-menu">
            <ul>
              <li>
                <a
                  aria-label="Legal"
                  href="https://registry.godaddy/legal"
                  target={"_blank"}
                >
                  Legal
                </a>
              </li>
              <li>
                <a
                  aria-label="Privacy Policy"
                  href="https://registry.godaddy/legal/privacy-policy"
                  target={"_blank"}
                >
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-bottom-sell-note">
            <p>Do not sell my personal information</p>
          </div>
        </div>
        <div className="footer-bottom-right order-md-1">
          <div className="footer-bottom-copyright">
            <p>
              Copyright © {new Date().getFullYear()} .TV, All Rights Reserved. 
            </p>
          </div>
          <div className="footer-bottom-note">
            <p>
              All copyrighted works and trademarks are the property of their
              respective owners and used here for informative purposes and does
              not imply endorsement or ownership.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterBottom;
