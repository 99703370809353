import React from "react";
import { useState } from "react";
import SearchLogo from "../Logos/SearchLogo";
import {
  validateNoCharacterDot,
  validateBlank,
  validSearch
} from "../../Services/validation";
import regex from "../../Constants/regValidation";

function SearchBox(props) {
  const [searchedText, setSearchedText] = useState("");

  return (
    <div className="page-search footer-search">
      <form
        action="/search"
        method="get"       
        onSubmit={(e) => validSearch(searchedText, e)}
      >
        <div className="d-flex align-items-center">
          <label className="no-show-label" htmlFor="DomainSearch">Domain Name</label>
          <input
            type="text"
            name="domain"
            className="form-control" id="DomainSearch"
            placeholder="Find your .TV domain"
            onKeyPress={(e) => validateNoCharacterDot(e)}
            defaultValue={props.domain}
            onChange={(e) => {
              let text = e.target.value;

              if (regex.test(text)) {
                setSearchedText("");
              } else {
                setSearchedText(text);
              }
            }}
          />

          <button type="submit" className="btn btn-primary">
            <SearchLogo />
            SEARCH
          </button>
        </div>
      </form>
    </div>
  );
}

export default SearchBox;
