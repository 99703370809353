import React from "react";
import GetIt from "../../Buttons/GetIt";

function DomainAvailBox({ domain }) {
  let actualDomain =
    domain.data.domain.split(".")[0] +
    "." +
    domain.data.domain.substring(domain.data.domain.indexOf(".") + 1);

  return (
    <div className="h-100 d-flex flex-column justify-content-center text-md-center py-4 py-md-5 px-4 tld-info-box">
      <h3>
        {domain.data.domain.split(".")[0]}
        <strong>
          .{domain.data.domain.substring(domain.data.domain.indexOf(".") + 1)}
        </strong>
      </h3>

      <div className="text-md-center">
        <GetIt actualDomain={actualDomain} />
      </div>
    </div>
  );
}

export default DomainAvailBox;
