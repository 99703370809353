import React from 'react';

function NoEntryLogo(props) {
    return (
        <svg width="40" height="39" viewBox="0 0 40 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 0C16.1433 0 12.3731 1.14366 9.16639 3.28634C5.95963 5.42903 3.46027 8.47451 1.98436 12.0377C0.508449 15.6008 0.122284 19.5216 0.874696 23.3043C1.62711 27.0869 3.4843 30.5615 6.21143 33.2886C8.93855 36.0157 12.4131 37.8729 16.1957 38.6253C19.9784 39.3777 23.8992 38.9916 27.4623 37.5157C31.0255 36.0397 34.071 33.5404 36.2137 30.3336C38.3564 27.1269 39.5 23.3567 39.5 19.5C39.494 14.3301 37.4377 9.37368 33.782 5.71801C30.1263 2.06235 25.1699 0.00597081 20 0ZM3.50001 19.5C3.49759 16.3643 4.38943 13.293 6.07089 10.6462C7.75236 7.9995 10.1537 5.88715 12.9933 4.55697C15.8329 3.2268 18.9929 2.73396 22.1026 3.13627C25.2124 3.53858 28.143 4.81936 30.5506 6.82836L7.32837 30.0505C4.85271 27.0925 3.49737 23.3573 3.50001 19.5ZM20 36C16.1427 36.0026 12.4075 34.6473 9.44947 32.1716L32.6716 8.94946C34.6806 11.357 35.9614 14.2876 36.3637 17.3974C36.7661 20.5071 36.2732 23.6671 34.943 26.5067C33.6129 29.3463 31.5005 31.7477 28.8538 33.4291C26.207 35.1106 23.1357 36.0024 20 36Z" fill="#444444"/>
    </svg> 
    );
}

export default NoEntryLogo;