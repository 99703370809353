import { createContext, useState } from "react";

export const UtmContext = createContext(null);

const ContextProvider = ({ children }) => {
  const [utmSource, setUtmSource] = useState("");
  const [utmCampaign, setUtmCampaign] = useState("");
  const [utmMedium, setUtmMedium] = useState("");
  const [utmTerm, setUtmTerm] = useState("");
  const [domainToTrack, setDomainToTrack] = useState("");
  const [activePage, setActivePage] = useState(null);

  return (
    <UtmContext.Provider
      value={{
        utmSource,
        setUtmSource,
        utmCampaign,
        setUtmCampaign,
        utmMedium,
        setUtmMedium,
        utmTerm,
        setUtmTerm,
        domainToTrack,
        setDomainToTrack,
        activePage,
        setActivePage,
      }}
    >
      {children}
    </UtmContext.Provider>
  );
};

export default ContextProvider;
