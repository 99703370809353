import React from "react";

function YoutubeLogo(props) {
  return (
    <a
      href="https://www.youtube.com/channel/UCegVIK7fZNErFkBvcham71A" target={"_blank"} aria-label="Youtube">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 50 50"
        style={{ enableBackground: "new 0 0 50 50" }}
        xmlSpace="preserve"
      >
        <path
          className="st0"
          d="M20,32.44v-15l13.07,7.5L20,32.44z M48.95,12.81c-0.58-2.16-2.27-3.84-4.42-4.42C40.62,7.44,25,7.44,25,7.44s-15.62,0-19.52,1.07c-2.13,0.56-3.81,2.19-4.42,4.3c-0.72,4-1.08,8.06-1.05,12.12C-0.02,29,0.33,33.06,1.05,37.06c0.58,2.16,2.27,3.84,4.42,4.42C9.38,42.56,25,42.56,25,42.56s15.62,0,19.52-1.07c2.16-0.58,3.84-2.27,4.42-4.42c0.72-4,1.08-8.06,1.05-12.12C50.02,20.87,49.67,16.81,48.95,12.81z"
        />
      </svg>
    </a>
  );
}

export default YoutubeLogo;
