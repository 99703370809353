const featuredStoriesList = [
  {
    Name: "thecontentfarm.tv",
    Description:
      "The Content Farm is a global TV and multimedia development and production company based in Washington D.C. and with offices nationwide. Renowned for its innovative approach and award-winning team of diverse professionals – from business and tech experts to media enthusiasts and creative minds – The Content Farm specializes in crafting “fresh and engaging organic content” across TV, film, music, digital media, and apps.",
    image: "interviewthumbnails/20241104103036__multifeat_thumbnails_thecontentfarm.tv_743x429_2x.png",
    link: "/whosontv/thecontentfarm.tv",
  },
  {
    Name: "thehhn.tv",
    Description:
      "The biggest rap stars of this generation might be known for their bravado and unattainable wealth, but the creators of Hip Hop Network Television want to remind fans of all ages of the genre’s humble beginnings. From its birth in New York City in the 1970s to eventually serving as a muse for empowering Black and Latinx youth across all the boroughs. It spurred a generation of new artists including emcees, disc jockeys, breakdancers and graffiti artists. But of course Hip-Hop would eventually become a global movement, and today it has made an imprint on almost every facet of society, from priceless art to high-fashion",
    image: "interviewthumbnails/thehhn-tv-slide.png",
    link: "/whosontv/thehhn.tv",
  },
  {
    Name: "roaddirt.tv",
    Description:
      "For Rob Brooks, the “RIDE LIFE” is all the more worth living if he can share episodes from his journeys, long after he’s parked his motorcycle. He believes chronicling the highs and lows of his adventures can inform and inspire riders as well as the “moto-curious.” His goal is to build a digital following and community for the motorcycle riding world.",
    image: "interviewthumbnails/roaddirt-tv-slide.png",
    link: "/whosontv/roaddirt.tv",
  },
  {
    Name: "alexrobinson.tv",
    Description:
      "Dive into the world of Alex Robinson, a filmmaking virtuoso whose lens tells stories beyond boundaries. From WWII bomber restorations to heartwarming connections with therapy horses, Robinson's cinematic journey is a tapestry of extraordinary tales that redefine storytelling in filmmaking.",
    image: "interviewthumbnails/alexrobinson-img.png",
    link: "/whosontv/alexrobinson.tv",
  },
  {
    Name: "paulcostabile.tv",
    Description:
      "Although his interviews featuring Hollywood heavyweights could be seen on major television and cable networks across the country, Paul Costabile still yearned to build a digital presence on his own terms. He also wanted to highlight his work beyond the red carpet.",
    image: "interviewthumbnails/paulcostabile-img.jpg",
    link: "/whosontv/paulcostabile.tv",
  },
  {
    Name: "Boardroom.tv",
    Description:
      "Founded by 2x NBA Champion and Finals MVP Kevin Durant and entrepreneur Rich Kleiman, The Boardroom brings fans inside the business of sports as never seen before.",
    image: "interviewthumbnails/BoardroomLogo.png",
    link: "/whosontv/boardroom.tv",
  },
  {
    Name: "maff.tv",
    Description:
      "Music, Art, Fashion Forward touts 6,000+ hand-picked videos and 5,000+ creatives from around the world. Founded by professionals on a mission to empower underrepresented designers and artists globally, MAFF highlights and celebrates the things that bring us together and the beauty which differentiates us.",
    image: "interviewthumbnails/maffMarquee-img.jpg",
    link: "/whosontv/maff.tv",
  },
  {
    Name: "Cookingwithgranny.tv",
    Description:
      "Cooking with Granny is a multicultural, multifaceted, multi-generational homage to the best chefs we know — our grandmothers. The web series features 9 groovy grannies of 9 different cultures sharing cooking tips and galavanting on grocery shopping adventures. Themes include cultural preservation, wartime survival, immigration, and New York City.",
    image: "interviewthumbnails/CookingWithGranny.jpg",
    link: "/whosontv/cookingwithgranny.tv",
  },
  {
    Name: "TDN.TV",
    Description:
      "The fastest growing TV network all about “home.” TDN.TV gives a fresh perspective on the fashion and function of home: interior landscape and design, organization, entertaining, and living a better life at home.",
    image: "interviewthumbnails/multifeat_thumbnails_TDN_TV_743x429_2x.png",
    link: "/whosontv/tdn.tv",
  },
  {
    Name: "Ninjalifehacks.tv",
    Description:
      "Selling more than 2MM copies worldwide, Ninja Life Hacks is a childeren’s book series that touches on social and emotional themes like mindfulness, grit, and how to manage feelings like anger. Soon expanding into an animated series, Ninja Life Hacks helps children’s build self-confidence and prepares them for life’s challenges.",
    image: "interviewthumbnails/ninjalifehacksMarquee-img.jpg",
    link: "/whosontv/ninjalifehacks.tv",
  },
  {
    Name: "Tmilly.tv",
    Description:
      "Director and cinematographer Tim Milgram, who’s worked with brands like Mattel, Puma, and Zumba as well as artists like Ciara, Justin Timberlake, and Meghan Trainor, turned his love of dance into an online business offering dance classes from instructors who work with some of the biggest stars in the world.",
    image: "interviewthumbnails/tmillyMarque.jpg",
    link: "/whosontv/tmilly.tv",
  },

  {
    Name: "Brid.tv",
    Description:
      "Founded in Serbia, BridTV is a fast-growing technology company with a streaming and advertising video platform for businesses and brands. With offices in Los Angeles and Belgrade, BridTV is swiftly climbing toward the summit of online video publishing and advertising.",
    image: "interviewthumbnails/bridMarquee-img.jpg",
    link: "/whosontv/brid.tv",
  },
  {
    Name: "Pluto.tv",
    Description:
      "A streaming service startup who turned global and was acquired three(!) years after launching, Pluto TV is completely free for users and prides itself on taking an artisan approach to programming. The ad-supported service offers more than 250 live channels as well as on-demand movies and TV shows.",
    image: "interviewthumbnails/plutoMarqueImage.jpg",
    link: "/whosontv/pluto.tv",
  },
  {
    Name: "Piehole.tv",
    Description:
      "A fun, groovy team based out of California, Piehole specializes in making promotional brand and product videos that don’t suck. From fun, animated explainers to super-realistic software demo videos, Piehole.TV takes complicated concepts and transforms them into succinct marketing videos.",
    image: "interviewthumbnails/pieholeMarquee-img.jpg",
    link: "/whosontv/piehole.tv",
  },
  {
    Name: "Brianklein.tv",
    Description:
      'Brian Klein, an award-winning television director known for his work on "Top Gear" and collaborations with stars like James Corden, has over 25 years of experience directing for platforms like Netflix and BBC. During the COVID-19 pandemic, he transitioned into novel-writing, producing the bestselling thriller "The Counterfeit Candidate" and showcasing his diverse career on Brian Klein | Brian Klein Author | Brian Klein Novelist .',
    image: "thumbnails_BrianKlein_tv.jpg",
    link: "/whosontv/brianklein.tv",
  },
];
export default featuredStoriesList;
