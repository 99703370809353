import React, { useEffect, useState, useRef } from "react";
import HeaderSearch from "../HeaderSearch";
import TvFooter from "../TvFooter/TvFooter";
import TvHeader from "../TvHeader/TvHeader";
import PoliciesContent from "./PoliciesContent";
import FooterScroll from "../FooterScroll";
import FooterSearchbar from "../FooterSearchbar";
import { useIsInViewport } from "../../Services/tvAPI";
import { Helmet } from "react-helmet";

function Policies() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const ref2 = useRef(null);
  const isInViewport2 = useIsInViewport(ref2);

  const [footerSearchon, setFooterSearchon] = useState("headersearchshow");

  useEffect(() => {
    if (isInViewport2 === true) {
      setFooterSearchon("headersearchhide");
    } else {
      setFooterSearchon("headersearchshow");
    }
  }, [isInViewport2]);

  return (
    <>
      <Helmet>
        <title>.TV Policies &#38; Domain Management Information &#124; .TV Domain</title> 
        <meta name="website" content="http://turnon.tv/policies"/>
        <meta name="description" content="Find and access available policy documents for the .TV top-level domain. Discover and download information as needed."/>
      </Helmet>
      <TvHeader />
      <div className={footerSearchon + ` home-page-search`}>
        <HeaderSearch url={window.location.pathname} />
      </div>
      <PoliciesContent />
      <FooterScroll />
      <div ref={ref2}>
        <FooterSearchbar url={window.location.pathname} />
      </div>
      <TvFooter />
    </>
  );
}

export default Policies;
