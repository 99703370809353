import React, { useEffect, useRef } from "react";

function AvailableDomainStatus({ domain }) {
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView();
  });
  return (
    <div className="section-row">
      <div className="d-flex flex-column text-center py-5 px-4 rounded-0 tld-available-info-box">
        <div className="tld-available-info">
          <h3>Your domain is available!</h3>
          <h2 ref={scollToRef}>{domain}</h2>
        </div>
      </div>
    </div>
  );
}

export default AvailableDomainStatus;
