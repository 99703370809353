import React from "react";
import featuredStoriesList from "../../Constants/Stories/featuredStoriesList";
function InterviewList(props) {
  return (
    <div className="section-row overflow-hidden mb-md-3">
      <div className="container">
        <div className="showcase-list">
          <div className="row">
            {featuredStoriesList.map((item, index) => {
              return (
                <div className="col-md-6 mb-4 mb-md-5">
                  <div className="showcase-box">
                    <figure>
                      <a aria-label="showcase" href={item.link}>
                        <img
                          className="w-100"
                          src={`${process.env.REACT_APP_TLD_IMAGE_URL}images/${item.image}`}
                          alt="showcase image"
                        />
                      </a>
                    </figure>
                    <p className="mt-3 mb-4">{item.Description}</p>
                    <a
                      aria-label="Showcase Details"
                      href={item.link}
                      className="btn btn-primary"
                    >
                      Learn more{" "}
                      <span className="no-show-label">{item.Name}</span>
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default InterviewList;
