import { useState, useEffect, useContext } from "react";
import { motion, Variants } from "framer-motion";
import headerItems from "../../Constants/headerItems";
import { useLocation } from "react-router-dom";
import { UtmContext } from "../../Context/ContextProvider";
const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 200, damping: 25 },
  },
  closed: { opacity: 0, y: -20, transition: { duration: 0.2 } },
};

export default function MenuAnimation() {
  const { activePage } = useContext(UtmContext);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  let timeDelay = 300;
  if (location.pathname === "/") {
    timeDelay = 2500;
  }
  useEffect(() => {
    setTimeout(() => {
      setIsOpen(!isOpen);
    }, timeDelay);
  }, []);

  return (
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      className="menu"
    >
      <motion.ul
        className="navbar-nav"
        variants={{
          open: {
            clipPath: "inset(0% 0% 0% 0% round 10px)",
            transition: {
              type: "spring",
              bounce: 0,
              duration: 5,
              delayChildren: 1,
              staggerChildren: -0.2,
            },
          },
        }}
        style={{ pointerEvents: isOpen ? "auto" : "none" }}
      >
        {headerItems.map((item, index) => {
          return (
            <motion.li
              variants={itemVariants}
              className={`nav-item ${
                activePage === item[2] ? "active" : "inactive"
              }`}
              key={index}
            >
              <a className={`nav-link`} aria-current="page" aria-label='Menu Name' href={item[1]}>
                {item[0]}
              </a>
            </motion.li>
          );
        })}
      </motion.ul>
    </motion.nav>
  );
}
