import React from "react";
import VolumeupdownAnimation from "../VolumeupdownAnimation";

function StoriesGetfeatured(props) {
  return (
    <div className="section-row mb-4 mb-md-5">
      <div className="container">
        <div className="section-title text-center mb-4 mb-md-5 pb-md-3">
          <div className="pb-3 pb-md-5">
            <div className="mb-4">
              <VolumeupdownAnimation />
            </div>
          </div>
          <h2 className="mb-3">ALREADY ON .TV? </h2>
          <p className="mb-4 mb-md-5">
            We want to show off your site! Send us your story for a chance to be
            featured here or on our marketing channels.
          </p>
          <a
            href="https://feedback.secureserver.net/jfe/form/SV_9shqd9ZyAsiUYKy"
            className="btn btn-primary"
            target={"_blank"}
          >
            GET FEATURED
          </a>
        </div>
      </div>
    </div>
  );
}

export default StoriesGetfeatured;
