import React from "react";
import NoEntryLogo from "../../Logos/NoEntryLogo";

function DomainUnavailBox({ domain, whois }) {
  return (
    <>
      <div className="h-100 d-flex flex-column justify-content-center text-md-center  py-5 px-4 tld-info-box tld-not-available">
        <p className="font-l mb-1">This domain is unavailable.</p>
        <h3 className="mt-3 mt-md-4 mb-4 d-flex flex-wrap align-items-center justify-content-md-center">
          <figure>
            <NoEntryLogo />
          </figure>
          <del>
            {domain.data.domain.split(".")[0]}
            <strong>
              .
              {domain.data.domain.substring(
                domain.data.domain.indexOf(".") + 1
              )}
            </strong>
          </del>
        </h3>
        <p className="pt-2 mb-0">
          <a
            href="/#"
            data-bs-toggle="modal"
            data-bs-target={
              "#" +
              domain.data.domain.substring(domain.data.domain.indexOf(".") + 1)
            }
          >
            <u>View WHOIS Information</u>
          </a>
        </p>
      </div>
      <div
        className="modal fade"
        id={domain.data.domain.substring(domain.data.domain.indexOf(".") + 1)}
        tabIndex="-1"
        aria-labelledby="WHOISModalModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered ">
          <div className="modal-content p-4 p-xl-5">
            <div className="modal-header px-0 pt-0 pb-3 pb-md-4 flex-column align-items-start">
              <h5 className="modal-small-title">WHOIS Lookup</h5>
              <h2 className="modal-title">{whois["Domain Name"]}</h2>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body px-0 pb-0 pt-3 pt-md-4">
              <div className="row g-3">
                {/* <div className="col-xl-4">
                  <h4 className="mb-3">Registrant</h4>
                  <div className="who-info-box">
                    <h6>{whois["Registrant Name"]}</h6>
                    <address>
                      {whois["Registrant Street"]} <br />
                      {whois["Registrant City"] +
                        "," +
                        whois["Registrant State/Province"] +
                        "," +
                        whois["Registrant Postal Code"] +
                        "," +
                        whois["Registrant Country"]}
                      <br /> {whois["Registrant Organization"]}
                    </address>
                    <span> {whois["Registrant Phone"]}</span>
                    <span>{whois["Registrant Email"]}</span>
                  </div>
                </div>
                <div className="col-xl-4">
                  <h4 className="mb-3">Admin</h4>
                  <div className="who-info-box">
                    <h6>{whois["Admin Name"]}</h6>
                    <address>
                      {whois["Admin Street"]} <br />
                      {whois["Admin City"] +
                        "," +
                        whois["Admin State/Province"] +
                        "," +
                        whois["Admin Postal Code"] +
                        "," +
                        whois["Admin Country"]}
                      <br /> {whois["Admin Organization"]}
                    </address>
                    <span> {whois["Admin Phone"]}</span>
                    <span>{whois["Admin Email"]}</span>
                  </div>
                </div>
                <div className="col-xl-4">
                  <h4 className="mb-3">Technical</h4>
                  <div className="who-info-box">
                    <h6>{whois["Tech Name"]}</h6>
                    <address>
                      {whois["Tech Street"]} <br />
                      {whois["Tech City"] +
                        "," +
                        whois["Tech State/Province"] +
                        "," +
                        whois["Tech Postal Code"] +
                        "," +
                        whois["Tech Country"]}
                      <br /> {whois["Tech Organization"]}
                    </address>
                    <span> {whois["Tech Phone"]}</span>
                    <span>{whois["Tech Email"]}</span>
                  </div>
                </div> */}
              </div>
              <div className="accordion-technical-details mt-4 mb-3">
                {/* <div className="accordion" id="accordionTechnical">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTechnical">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTechnical"
                        aria-expanded="true"
                        aria-controls="collapseTechnical"
                      >
                        <span className="accordion-hide">Show </span>
                        <span className="accordion-show">Hide </span> technical
                        details
                      </button>
                    </h2>
                    <div
                      id="collapseTechnical"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTechnical"
                      data-bs-parent="#accordionTechnical"
                    > */}
                      <div className="accordion-body">
                        <p>
                          Domain Name: {whois["Domain Name"]}
                          <br />
                          Registry Domain ID: {whois["Registry Domain ID"]}
                          <br />
                          Registrar WHOIS Server:{" "}
                          {whois["Registrar WHOIS Server"]}
                          <br />
                          Registrar URL: {whois["Registrar URL"]}
                          <br />
                          Updated Date: {whois["Updated Date"]}
                          <br />
                          Creation Date: {whois["Creation Date"]}
                          <br />
                          Registry Expiry Date: {whois["Registry Expiry Date"]}
                          <br />
                          Registrar: {whois["Registrar"]}
                          <br />
                          Registrar IANA ID: {whois["Registrar IANA ID"]}
                          <br />
                          Registrar Abuse Contact Email:{" "}
                          {whois["Registrar Abuse Contact Email"]}
                          <br />
                          Registrar Abuse Contact Phone:{" "}
                          {whois["Registrar Abuse Contact Phone"]}
                          <br />
                          Domain Status: {whois["Domain Status"] + " "}
                          <br />
                          Name Server: {whois["Name Server"] + " "}
                          <br />
                          DNSSEC: {whois["DNSSEC"]}
                          <br />
                          URL of the ICANN Whois Inaccuracy Complaint Form:{" "}
                          {
                            whois[
                              "URL of the ICANN Whois Inaccuracy Complaint Form"
                            ]
                          }{" "}
                          {whois[">>> Last update of WHOIS database"]}
                        </p>

                        <p>
                          For more information on Whois status codes, please
                          visit https://icann.org/epp
                        </p>

                        <p>
                          NOTICE: The expiration date displayed in this record
                          is the date the registrar's sponsorship of the domain
                          name registration in the registry is currently set to
                          expire. This date does not necessarily reflect the
                          expiration date of the domain name registrant's
                          agreement with the sponsoring registrar. Users may
                          consult the sponsoring registrar's Whois database to
                          view the registrar's reported date of expiration for
                          this registration.
                        </p>

                      </div>
                    {/* </div>
                  </div>
                </div> */}
              </div>
              <div className="technical-note">
                *This information was obtained from a different whois server, so
                we cannot verify its authenticity.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DomainUnavailBox;
