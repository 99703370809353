import React from "react";
import VolumeupdownAnimation from "../VolumeupdownAnimation";
import { Reveal, Fade, Roll, Zoom } from "react-awesome-reveal";

function AccrditedRegistrarReseller() {
  return (
    <>
      <Zoom>
        <div className="section-row pb-0">
          <div className="container">
            <div className="section-title text-center mb-4 mb-md-5 pb-md-3">
              <h2 className="mb-3">
                Become a .TV Accredited
                <br />
                Registrar or a Reseller.
              </h2>
              <p className="mb-4 mb-md-5">
              Partner with us to offer .TV domains to your customers and clients worldwide. You’ve got choices for your domain name portfolio, and with .TV you’ll know you’re offering a trusted, established option to millions of people, brands, and businesses joining the digital landscape every day.
              </p>
              <a href="#Reseller" aria-label='REQUEST FORM' className="btn btn-primary">
                REQUEST FORM
              </a>
            </div>
            <div className="mt-5 mb-0 mb-md-0">
              <VolumeupdownAnimation />
            </div>
          </div>
        </div>
      </Zoom>
    </>
  );
}

export default AccrditedRegistrarReseller;
